var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "eventAgentEntry" },
    [
      _vm.currentStep === _vm.Steps.Agreement
        ? [_c("agent-intro", { attrs: { displayType: "campaign" } })]
        : _vm.currentStep === _vm.Steps.Entry
        ? [
            _c(
              "div",
              { staticClass: "event-entry bg-gray" },
              [
                _c("AgentEntryForm", {
                  on: {
                    complete: function ($event) {
                      return _vm.updateStep(_vm.Steps.Complete)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm.currentStep === _vm.Steps.Complete
        ? [_c("AgentEntryComplete")]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }