var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row terms-box" }, [
      _c(
        "div",
        { staticClass: "col-lg-12" },
        [
          _c("center", [_c("h1", [_vm._v("Nola有料サービス利用規約")])]),
          _c("div", { staticClass: "date" }, [_vm._v("2020年9月19日 初版")]),
          _vm._m(0),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("p", [
        _vm._v(
          " このNola有料サービス利用規約（以下「有料サービス利用規約」といいます）には、本有料サービスの利用条件及び株式会社indent（以下「当社」といいます）とユーザーの皆様との間の権利義務関係が定められています。本有料サービスの利用に際しては、有料サービス利用規約の全文をお読みいただいたうえで、有料サービス利用規約に同意いただく必要があります。 "
        ),
      ]),
      _c("h2", [_vm._v("第１条　適用関係")]),
      _c("p", [
        _vm._v(
          " 1. この有料サービス利用規約は、当社が本サイト上で提供する有料のサービスを、ユーザーが利用する際の一切の行為に適用します。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 有料サービス利用規約は、本有料サービスの利用条件を定めるものです。ユーザーは、Nola利用規約（以下「利用規約」といいます）及び有料サービス利用規約に従い本有料サービスを利用するものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーは、本有料サービスを利用することにより、利用規約及び有料サービス利用規約の全ての記載内容について同意したものとみなされます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 有料サービス利用規約に定めのない事項に関しては、利用規約の定めを適用するものとし、有料サービス利用規約の規定と利用規約の規定に矛盾が生じる場合には有料サービス利用規約の規定が優先して適用されるものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第2条　定義")]),
      _c("p", [
        _vm._v(
          "有料サービス利用規約において使用する用語の意義は、次の各号に定めるとおりとします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 1. 本サイト：当社が運営する「Nola」と称するウェブサイト（PCサイト　https://nola-novel.com/、アプリ (IOS)　 https://apps.apple.com/jp/app/id1468307521、アプリ (Android) https://play.google.com/store/apps/details?id=com.nola.app&hl=ja）といいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 本有料サービス：ユーザーが利用料金等を支払うことにより、Nola内の一部の機能も制限なく利用できるサービスをいいます。 "
        ),
      ]),
      _c("p", [_vm._v("3. ユーザー：本有料サービスを利用する者をいいます。")]),
      _c("p", [
        _vm._v(
          "4. 商品等：本サイト上において販売される商品及びサービスをいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "5. 利用料金等：ユーザーが本有料サービスを利用したことによって生じた当社に対する債務をいいます。"
        ),
      ]),
      _c("p", [_vm._v("6. 決済手段：利用料金等の支払方法をいいます。")]),
      _c("p", [
        _vm._v(
          " 7. 決済サービス：本サイト上で成立した当社とユーザーとの間の取引の代金決済の方法であって、ユーザーが予め登録した決済手段の情報を用いることにより、決済手段の情報をその都度入力したり送信することなく、インターネット上で代金決済を行えるようにするサービスをいいます。 "
        ),
      ]),
      _c("h2", [_vm._v("第3条　有料サービス利用規約の変更")]),
      _c("p", [
        _vm._v(
          " 当社は、当社の判断により、有料サービス利用規約をいつでも任意に変更することができます。変更後の有料サービス利用規約は、当社が別途定める場合を除いて、本サイト上に表示した時点より効力を生じるものとします。ユーザーが、有料サービス利用規約の変更の効力が生じた後、本有料サービスをご利用になる場合は、変更後の有料サービス利用規約に同意したものとみなされます。 "
        ),
      ]),
      _c("h2", [_vm._v("第4条　利用資格")]),
      _c("p", [
        _vm._v(
          "本有料サービスの利用は、当社が指定する決済手段を持っている方に限るものとします。"
        ),
      ]),
      _c("h2", [_vm._v("第5条　ユーザーの責任")]),
      _c("p", [
        _vm._v(
          " ユーザーは、ユーザー自身の自己責任において本有料サービスを利用するものとし、本有料サービスを利用してなされた一切の行為及びその結果について一切の責任を負います。 "
        ),
      ]),
      _c("h2", [_vm._v("第6条　パスワード及び決済手段の管理")]),
      _c("p", [
        _vm._v(
          "1. ユーザーは、決済サービスのパスワードの管理について一切の責任を持つものとします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. ユーザーは、決済サービスに登録しているクレジットカード又は銀行口座その他の決済方法の情報が自己の管理に属さなくなったときには、自己の管理に属する使用可能な別の決済方法の情報を決済手段として改めて登録しなければならないものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーは、決済サービスに登録している情報の内容が変更されたときには、変更後の情報を改めて登録しなければならないものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. ユーザーの決済サービスのパスワードや決済手段が第三者に利用される、又は決済サービスに登録されている情報の内容が不正確であったことによってユーザーに生じた損害等につきましては、当社に重大な過失がある場合を除き、当社はいかなる責任も負いません。 "
        ),
      ]),
      _c("h2", [_vm._v("第7条　サービスの変更等")]),
      _c("p", [
        _vm._v(
          "当社は、当社の都合により、本有料サービスをいつでも任意に追加、変更、中断、終了することができます。"
        ),
      ]),
      _c("h2", [_vm._v("第8条　利用料金等")]),
      _c("p", [
        _vm._v("1. 本有料サービスの利用料金等は、別に定めるとおりとします。"),
      ]),
      _c("p", [
        _vm._v(
          " 2. 利用料金等は、決済サービスで手続きを完了させた日(以下「起算日」といいます)から発生し、お申し込みプランの対象期間の経過時に自動更新されます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーが本有料サービスを利用したか否かにかかわらず、当社所定の解約手続き及び決済会社の定める決済の解約手続きを完了させない限り、お申し込みプランの対象期間の経過時に自動更新され、利用料金等が発生します。会員は予めこれに承諾します。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. ユーザーが解約を希望する場合、お申し込みプランの対象期間の最終日までに、解約手続きを完了させるものとします。但し、対象期間の途中で解約手続きを行った場合であっても、当該お申し込みプランの対象期間にかかる利用料金等が発生し、日割り計算による返金対応等は致しません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "5. 当社は、決済サービスによって行われた代金決済についての領収書等は発行いたしません。"
        ),
      ]),
      _c("h2", [_vm._v("第9条　決済手段")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーは、自己の管理に属する有効なクレジットカード又は銀行口座その他の決済方法の情報を決済手段として決済サービスに登録し、当該決済手段によって利用料金等を支払うものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. 前項の決済手段は、次に掲げるいずれかの方法によるものとします。"
        ),
      ]),
      _c("p", [_vm._v("　　1. クレジットカード")]),
      _c("p", [_vm._v("　　2. その他当社が定める方法")]),
      _c("p", [
        _vm._v(
          " 3. 決済手段がクレジットカードによる場合、利用料金等は当該クレジット会社の会員規約において定められた振替日にユーザー指定の口座から引き落とされるものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第10条　遅延利息")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーが利用料金等の支払いを支払期日より遅延した場合には、支払期日の翌日から支払いの日の前日までの日数について年利14.6％の割合で計算した額の遅延利息金を当社に支払うものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. 前項の支払いに必要な振込み手数料その他の費用は、当該ユーザーが負担するものとします。"
        ),
      ]),
      _c("h2", [_vm._v("第11条　ユーザーによる解約")]),
      _c("p", [
        _vm._v(
          " ユーザーが本有料サービスの利用を停止する場合は、当社所定の方法で解約を申し出るものとします。当社は、既に受領した利用料金等の返金対応等には一切応じないものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第12条　利用停止")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーが利用料金等の支払いを支払期日より遅延した場合には、当社は、当該ユーザーに通知することなく、提供中の全ての有料サービスの利用を停止することができるものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 決済サービスに決済手段として登録されたクレジットカードやその他の決済方法の利用が停止された場合には、当社は、当該ユーザーに通知することなく、提供中の全ての有料サービスの利用を停止することができるものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーが、利用規約又は有料サービス利用規約に反する行為をしたと当社が認めた場合には、当社は、当該ユーザーに通知することなく、提供中の全ての有料サービスの利用を停止することができるものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 前3項に定める場合には、利用停止の対応を行った日の属するお申し込みプランの対象期間の最終日までの利用料金等が発生するものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第13条　責任の制限")]),
      _c("p", [
        _vm._v(
          " 当社の重過失により、ユーザーが本有料サービスを一切利用できない状態が生じた場合には、当社は、当該ユーザーにおいて当該状態が発生したことを知った時から起算して24時間以上利用不能の状態が継続した場合に限り、当該状態が生じた月に発生する月額サービス料金の合計額の30分の1に、利用不能の日数を乗じた額（円未満は切り捨てるものとします。）を限度として、当該ユーザーに発生した現実の損害の賠償請求に応じるものとします。ただし、(1)定期的又は緊急に本有料サービスを提供するためのネッワーク、サーバー、システムの保守点検や更新等を行う場合、(2)本有料サービスを提供するためのシステムの故障、通信回線の中断等、運用上又は技術上やむを得ない事象が発生した場合、(3)火災、停電、地震、洪水、津波、天災地変等の不可抗力により本有料サービスの提供ができなくなった場合、(4)その他当社が停止又は中断を必要と判断した場合などにより生じた損害等については、当社は賠償責任を負わないものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第14条　免責")]),
      _c("p", [
        _vm._v(
          " 当社は、本有料サービスの内容の追加、変更、又は本有料サービスの中断、終了によって生じたいかなる損害についても、一切責任を負いません。アクセス過多、その他予期せぬ要因で表示速度の低下や障害等が生じた場合も同様とします。 "
        ),
      ]),
      _c("h2", [_vm._v("第15条　準拠法及び管轄裁判所")]),
      _c("p", [
        _vm._v(
          "1. 利用規約及び有料サービス利用規約の準拠法は、日本法とします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. ユーザーと当社の間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }