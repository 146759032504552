
import Vue from "vue";
import AgentIntro from "@/components/organisms/AgentIntro.vue";
import { Novel, User } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { AgentIntro },
  computed: {
    user() {
      return this.$store.getters["userModule/user"];
    },
    isIntro() {
      return this.$route.path === "/agent/intro";
    },
    novels() {
      return this.$store.getters["novelModule/novels"].filter((i: Novel) => i.title !== "はじめてのNola");
    },
  },
  watch: {
    user: {
      handler(val) {
        if (val.agentStartedAt) {
          if (this.novels.length > 0) {
            sessionStorage.setItem("agentOnBoarding", "true");
          }
          this.$router.push({ name: "agent" });
        }
      },
      immediate: true,
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  user: User;
  isIntro: boolean;
  novels: Novel[];
}

interface Methods {}
