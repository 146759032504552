
import Vue from "vue";
import Plots from "@/components/organisms/Plots.vue";
import PlotList from "@/components/organisms/PlotList.vue";
import PublishSharedLink from "@/components/organisms/PublishSharedLink.vue";
import { Plot, SubPlot } from "@/lib/models";
import isMobile from "ismobilejs";
import SlideSharedLinkMenu from "@/components/organisms/SlideSharedLinkMenu.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { Plots, PlotList, PublishSharedLink, SlideSharedLinkMenu },
  props: {
    novelId: String,
    plotId: String,
  },
  async created() {
    const { $store, novelId, $router, $route } = this;

    if ($route.path.includes("edit")) {
      // 全体プロットのプロット編集画面でリロードした際に、
      // 白紙の画面が表示される不具合を防ぐため、プロット編集画面に遷移する
      $router.push({ name: "plotEdit", params: { novelId } });
      return;
    }
    if ($route.path.includes("change")) {
      // 全体プロットのモード変更画面でリロードされた場合、ルート設定 (path: "/:novelId/plot/:plotId?") により
      // URL の 3 番目のパラメータが plotId として渡される
      // そのため、computed の plot を使用せず(サブプロットを取得してしまう為)、直接全体プロット (plots) を取得する
      const plots = $store.getters["plotModule/plots"];

      // 全体プロットのモード変更画面でリロードした際に、
      // 白紙の画面が表示される不具合を防ぐため、モード変更画面に遷移する
      $router.push({
        name: "plotChange",
        params: { novelId },
        query: { mode: plots.mode || "kishoutenketsu" },
      });
      return;
    }
    const { dispatch } = $store;

    dispatch("plotModule/initialize", novelId);

    this.$store.dispatch("sharedLinkModule/initializeSharedLinkList", novelId);
    this.$store.dispatch("sharedLinkModule/initializeSharedLinkListBelongOwner");
    this.$store.dispatch("sharedLinkModule/initializeCommentListBelongOwner", novelId);
  },
  computed: {
    plot() {
      const { $store, plotId } = this;

      if (plotId) {
        return $store.getters["plotModule/subPlot"](plotId);
      }
      return $store.getters["plotModule/plots"];
    },
    subPlots() {
      const { $store } = this;
      return $store.getters["plotModule/subPlots"];
    },
    isShowPublishSharedLink() {
      return this.$store.getters["sharedLinkModule/isShowPublishSharedLink"];
    },
    isEnableMobile() {
      return !isMobile().phone && !(isMobile().tablet && window.innerWidth < 2048);
    },
    isHamburgerMenuOpen() {
      return this.$store.getters["plotModule/isHamburgerMenuOpen"];
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
}

interface Data {}

interface Computed {
  plot: Plot | SubPlot;
  subPlots: SubPlot[];
  isShowPublishSharedLink: boolean;
  isEnableMobile: boolean;
  isHamburgerMenuOpen: boolean;
}

interface Methods {}
