
import Vue from "vue";

export default Vue.extend<{}, {}, Computed, {}>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  computed: {
    title() {
      return "アカウントを削除しました";
    },
    description() {
      return "この度はNolaをご利用いただきありがとうございました。\nまだまだツールとして至らない点や未熟な点が多く、ご不便をおかけしてしまい大変申し訳ございませんでした。これからも機能追加やバグの改修などに努めて参りたいと思いますので、今後またご利用いただける際は何卒よろしくお願いいたします。";
    },
  },
});

interface Computed {
  title: string;
  description: string;
}
