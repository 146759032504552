var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.closeDialog.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-box" },
        [
          [
            _vm._m(0),
            _c("div", { staticClass: "dialog-body" }, [
              _c("h1", [_vm._v("ご執筆 お疲れさまでした！")]),
              _c("div", { staticClass: "text-container" }, [
                _vm._v(_vm._s(_vm.promotionMessage)),
              ]),
              _c("hr"),
              _vm._m(1),
            ]),
            _c("footer", [
              !_vm.useWorkCompletedDesign
                ? _c(
                    "button",
                    {
                      staticClass: "button agent-link",
                      on: { click: _vm.onNavigateToAgent },
                    },
                    [_vm._v("連携する")]
                  )
                : _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button agent-move",
                        on: { click: _vm.onNavigateToAgent },
                      },
                      [_vm._v("Nolaエージェントに移動する")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "close-text",
                        on: { click: _vm.closeDialog },
                      },
                      [_vm._v("閉じる")]
                    ),
                  ]),
            ]),
          ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dialog-header" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/agent/promotion.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-container primary" }, [
      _vm._v(" Nolaエージェントに作品を連携して、様々な出版社からの"),
      _c("span", { staticClass: "highlighted-text" }, [
        _vm._v("商業化の可能性"),
      ]),
      _vm._v("を拡げてみませんか？ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }