
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import { showNotifyDialog } from "@/lib/dialog";
import { compareAsc, format } from "date-fns";
import ja from "date-fns/locale/ja";
import axiosBase from "axios";
import { UserClient } from "@/lib/clients";
import { NolaPageName, NolaItemId } from "@/lib/utils/analytics";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { createUrlWithUtmParams } from "@/lib/utils/url";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    VueLoading,
  },
  props: {
    id: String,
  },
  async mounted() {
    // microCMSから返却されたHTMLのタグにスタイルを適用する為に、コンポーネントがマウントされたときにスタイルを追加する
    this.addGlobalStyle();
  },
  beforeDestroy() {
    // 本コンポーネントで追加したHTMLタグに対するスタイルが、他のコンポーネントに影響しないように
    // コンポーネントが破棄される前にスタイルを削除する
    this.removeGlobalStyle();
  },
  async created() {
    const { id } = this;

    const contestRequest = await axios.get(`/contest/${id}`);
    if (contestRequest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }
    this.contest = contestRequest.data;
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      contest: {},
      requireNolaNovelRegistration: false,
    };
  },
  computed: {
    contestId() {
      const { contest } = this;
      return contest.contestId;
    },
    title() {
      const { contest } = this;
      return contest.contest;
    },
    department() {
      const { contest } = this;
      return contest.department;
    },
    free() {
      const { contest } = this;
      return contest.free;
    },
    image() {
      const { contest } = this;
      return contest.image.url;
    },
    referenceSource() {
      const { contest } = this;
      return contest.referenceSource;
    },
    deadline() {
      const { contest } = this;
      const { deadline } = contest;

      if (!deadline) return "-";

      return format(deadline, "YYYY/MM/DD（dd） HH:mm", { locale: ja });
    },
    genre() {
      const { contest } = this;
      return contest.genre;
    },
    category() {
      const { contest } = this;
      return contest.category;
    },
    award() {
      const { contest } = this;
      return contest.award;
    },
    content() {
      const { contest } = this;
      return contest.content;
    },
    homepage() {
      const { contest } = this;
      return contest.homepage;
    },
    entryForm() {
      const { contest } = this;
      return contest.entryForm;
    },
    isExpired() {
      const { contest } = this;
      const { deadline } = contest;

      if (!deadline) return false;

      const currentDate = format(new Date(), "YYYY-MM-DD");
      const contestDeadline = format(deadline, "YYYY-MM-DD");

      return compareAsc(currentDate, contestDeadline) === 1;
    },
    organizer() {
      const { contest } = this;
      return contest.organizer ? `主催：${contest.organizer}` : undefined;
    },
    themeName() {
      const { contest } = this;
      return contest.themeName;
    },
  },
  methods: {
    onClickHomepage() {
      const { homepage } = this;
      window.open(homepage);
    },
    onClickEntry() {
      const { id, contestId, entryForm } = this;

      // エージェント連携CPかどうかで動線を変える
      if (contestId) {
        if (this.contest.isAgentCampaign) {
          this.$router.push({ name: "eventAgentEntry", params: { id, campaignId: contestId } });
        } else {
          this.$router.push({ name: "eventContestEntry", params: { id, contestId } });
        }
      } else {
        window.open(entryForm);
      }
    },
    addGlobalStyle() {
      const style = document.createElement("style");
      style.id = "contest-global-style";
      style.innerHTML = `
        h1 {
          font-size: 20px;
          margin: 30px 0 20px;
        }

        h2 {
          font-size: 18px;
          margin: 30px auto;
          color: #e26762;
          text-align: center;
          border-bottom: 1px solid #e26762;
          line-height: 1.2;
          width: fit-content;
        }

        h3 {
          font-size: 18px;
          margin: 30px 0 15px;
          color: #1e1e1e;
          border-bottom: 3px solid #d9d9d9;
          padding-bottom: 5px;
        }

        h4 {
          font-size: 16px;
          margin: 20px 0 0;
          color: #1e1e1e;
          line-height: 1.8;
        }

        h5 {
          font-size: 14px;
          margin: 16px 0 10px;
          line-height: 1.8;
        }

        p {
          font-size: 14px;
          margin-bottom: 16px;
          color: #404040;
        }

        pre {
          font-size: 14px;
          padding: 16px;
          margin: 16px 0;
          color: #909090;
          background-color: #f0f0f0;
          border-radius: 5px;
        }

        ul {
          padding-inline-start: 20px;
          margin-block-start: 16px;
          margin-block-end: 16px;
          line-height: 1.8;
        }
      `;
      document.head.appendChild(style);
    },
    removeGlobalStyle() {
      const style = document.getElementById("contest-global-style");
      if (style) {
        style.remove();
      }
    },
    async navigateToNolaNovel() {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const aboutUrl = await service.genAboutUrl();

      window.open(
        createUrlWithUtmParams(aboutUrl, NolaPageName.Detail, NolaItemId.UseNolaNovelButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
  },
  watch: {
    async contestId(value) {
      if (value && !this.contest.isAgentCampaign) {
        const result = await this.$store.dispatch("nolaContestModule/fetchContest", this.contestId);

        // Nolaノベルコンテストの場合はアカウント検証
        if (result.platform === "nolaNovel") {
          this.$store.dispatch("nolaContestModule/fetchContest", this.contestId);
          const userClient = new UserClient();
          const results = await userClient.checkConnectedOtherServices();
          this.requireNolaNovelRegistration = !results.nolaNovel.result;
        }
      }
    },
  },
});

interface Props {
  id: string;
}

interface Data {
  isLoading: boolean;
  contest: Contest;
  requireNolaNovelRegistration: boolean;
}

interface Computed {
  contestId?: string;
  title: string;
  department: string;
  free: string;
  image?: string;
  referenceSource: string;
  deadline: string;
  genre: string;
  category: string;
  award: string;
  content: string;
  homepage: string;
  entryForm: string;
  isExpired: boolean;
  organizer?: string;
  themeName?: string;
}

interface Methods {
  onClickHomepage(): void;
  onClickEntry(): void;
  addGlobalStyle(): void;
  removeGlobalStyle(): void;
  navigateToNolaNovel(): Promise<void>;
}

type Contest = {
  [key: string]: any;
};
