var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "content-container" },
    [
      _setup.commerceDebutGuideStore.selectedMenu === "menu2"
        ? [
            _c(_setup.AgentIntro, {
              staticClass: "content",
              attrs: { displayType: "intro" },
            }),
          ]
        : [
            _c("iframe", {
              staticClass: "content",
              attrs: {
                src: _setup.iframeSrc,
                frameborder: "0",
                allowfullscreen: "",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }