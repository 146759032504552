var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.$props.settings, function (setting) {
        return _c(
          "div",
          { key: setting.id, staticClass: "checkbox-group" },
          [
            _c("div", { staticClass: "label" }, [_vm._v(_vm._s(setting.name))]),
            _c("div", [
              _vm._v(
                _vm._s(
                  setting.operation === "OR"
                    ? "いずれか1つのタグを付与"
                    : "全てのタグを付与"
                )
              ),
            ]),
            _vm._l(setting.options, function (option) {
              return _c(
                "div",
                {
                  key: option.id,
                  class: {
                    disabled:
                      option.disabled || _vm.alreadyChecked(option.name),
                  },
                },
                [
                  _c(
                    "CheckboxItem",
                    {
                      attrs: {
                        checked: _vm.$props.item?.tags?.includes(option.name),
                      },
                      on: { check: (v) => _vm.onClick(option.name, v) },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "checkbox-label",
                          class: { readonly: _vm.$props.readonly },
                        },
                        [_vm._v(" #" + _vm._s(option.name) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      _vm.$props.error
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.$props.error)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }