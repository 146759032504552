var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("header", { attrs: { id: "header" } }, [
    _c(
      "div",
      { staticClass: "left-buttons" },
      [
        _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
          _c("img", {
            staticClass: "logo-img",
            attrs: { src: require("@/assets/img/logo.png") },
          }),
        ]),
        _c(
          "div",
          { staticClass: "links" },
          [
            _c(
              "router-link",
              {
                staticClass: "link-item",
                class: { active: _vm.isActivePath("/home") },
                attrs: { to: "/" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickLink("ホーム")
                  },
                },
              },
              [_vm._v(" ホーム ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "link-item",
                class: {
                  active: _vm.isActivePath("/nolanovel"),
                  batch: _vm.isUnreadFromNolaNovel,
                },
                attrs: { to: "/nolanovel" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickLink("作品投稿")
                  },
                },
              },
              [_vm._v(" 作品投稿 ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "link-item tutorial-agent",
                class: {
                  active: _vm.isActivePath("/agent"),
                  batch: _vm.hasUnreadAgentNotifications,
                },
                attrs: { to: "/agent" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickLink("エージェント")
                  },
                },
              },
              [_vm._v(" エージェント ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "link-item",
                class: { active: _vm.isActivePath("/event/contest") },
                attrs: { to: "/event/contest" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickLink("コンテスト")
                  },
                },
              },
              [_vm._v(" コンテスト ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "link-item",
                class: { active: _vm.isActivePath("/event", "/event/contest") },
                attrs: { to: "/event/company" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickLink("商業募集")
                  },
                },
              },
              [_vm._v(" 商業募集 ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "link-item",
                class: { active: _vm.isActivePath("/knowhow") },
                attrs: { to: "/knowhow" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickLink("学習")
                  },
                },
              },
              [_vm._v(" 学習 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-buttons" },
      [
        _vm.isNolanovelOrAgentPath
          ? _c("image-icon", {
              staticClass: "icon-item beginners-icon",
              attrs: {
                to: { name: "commerceDebutGuide" },
                src: require("@/assets/img/icon/beginners-icon.webp"),
              },
            })
          : _vm._e(),
        _c(
          "router-link",
          { staticClass: "icon-item", attrs: { to: "/news" } },
          [_c("email-outline-icon", { attrs: { isUnread: _vm.isUnread } })],
          1
        ),
        _c("image-icon", {
          staticClass: "icon-item",
          attrs: {
            to: { name: "myPage" },
            src: require("@/assets/img/icon/mypage.png"),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }