
import Vue from "vue";

import ImageView from "@/components/atoms/ImageView.vue";
import { genre as genreList, category as categoryList, workStatus as workStatusList } from "@/lib/novelSettings";
import { Novel, User } from "@/lib/models";
import NovelListDialog, { NovelListDialogProps } from "@/components/ui/nolaNovel/NovelListDialog.vue";
import { Dialog } from "@/lib/utils";
import NolaNovelDelinkConfirm, {
  NolaNovelDelinkConfirmProps,
} from "@/components/ui/nolaNovel/NolaNovelDelinkConfirm.vue";
import NolaNovelUpdateConfirm, {
  NolaNovelUpdateConfirmProps,
} from "@/components/ui/nolaNovel/NolaNovelUpdateConfirm.vue";
import CupertinoAlertDialog, { CupertinoAlertDialogProps } from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import { UserClient } from "@/lib/clients";
import NolaNovelIntroduction from "@/components/ui/nolaNovel/NolaNovelIntroduction.vue";
import NovelDeleteConfirm, { NovelDeleteConfirmProps } from "@/components/ui/novel/NovelDeleteConfirm.vue";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";
import AgentPromotionDialog from "@/components/ui/AgentPromotionDialog.vue";

type NovelParam = {
  id: string;
  name: string;
  image: string;
};

interface Data {
  hasNolaNovelAccount: boolean;
  genre: NovelParam | null;
  category: NovelParam[] | null;
  workStatus: NovelParam | null;
  genreList: NovelParam[];
  categoryList: NovelParam[];
  workStatusList: NovelParam[];
}

interface Methods {
  initialize: () => void;

  deleteButtonClick: () => void;
  delinkNolaNovelButtonClick: () => void;
  postToNolaNovelButtonClick: (isPost: boolean) => void;

  showNolaNovelListDialog: (isPost: boolean) => void;
  showExportConfirmDialog: (id: string) => void;
  showNolaNovelDelinkConfirmDialog: (id: string) => void;
  showNovelDeleteConfirmDialog: (title: string) => void;
  navigateToNolaNovelEditPage: (id: string) => void;
}

interface Computed {
  novel?: Novel;
  imageKey: string;
  genreName?: string;
  categoryName?: string;
  workStatusName?: string;
  novelIdFromNolaNovel?: string;
  navigateToNolaNovelLink?: string;
  user: User;
  isAgentFeatureNotAgreed: boolean;
}

interface Props {
  novelId: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ImageView },

  props: {
    novelId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      hasNolaNovelAccount: false,
      genre: null,
      category: null,
      workStatus: null,
      genreList,
      categoryList,
      workStatusList,
    };
  },

  computed: {
    novel() {
      const { novelId } = this;
      const { getters } = this.$store;
      return getters["novelModule/novel"](novelId) as Novel;
    },
    imageKey() {
      const { novel } = this;
      if (!novel) {
        // return placeholder path.
        return "/img/placeholders/novel.png";
      }

      const { novelId, image } = novel;
      if (!image) {
        // return placeholder path.
        return "/img/placeholders/novel.png";
      }

      if (image && image.startsWith("file:")) {
        return `file:novels/${novelId}/cover.jpg`;
      }

      return image;
    },
    genreName() {
      const { genre } = this;
      if (!genre) {
        return undefined;
      }

      const currentGenre = this.genreList.find((x) => x.id === genre.id);
      if (!currentGenre) {
        return undefined;
      }

      return currentGenre.name;
    },
    categoryName() {
      const { category, categoryList } = this;
      if (!category) {
        return undefined;
      }

      const names = category.map((item) => {
        const findItem = categoryList.find((listItem) => listItem.id === item.id);
        if (findItem) {
          return findItem.name;
        }
        return "";
      });
      return names.reduce((acc, cur) => `${acc} / ${cur}`);
    },
    workStatusName() {
      const { workStatus, workStatusList } = this;
      if (!workStatus) {
        return this.workStatusList[0].name;
      }

      const currentWorkStatus = workStatusList.find((item) => item.id === workStatus.id);
      if (!currentWorkStatus) {
        return this.workStatusList[0].name;
      }

      return workStatus.name;
    },
    novelIdFromNolaNovel() {
      const { novel } = this;

      if (!novel) {
        return undefined;
      }

      const { associatedData } = novel;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;

      if (!id) {
        return undefined;
      }

      return id;
    },
    navigateToNolaNovelLink() {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;
      const { novelIdFromNolaNovel } = this;
      return createUrlWithUtmParams(
        `${url}/novel/${novelIdFromNolaNovel}`,
        NolaPageName.Detail,
        NolaItemId.LinkedNolaNovelIdLink
      );
    },
    user() {
      return this.$store.getters["userModule/user"];
    },
    isAgentFeatureNotAgreed() {
      return this.user.agentStartedAt === null;
    },
  },

  async created() {
    const { initialize } = this;
    initialize();
  },

  methods: {
    // initialize

    async initialize() {
      const { novel } = this;

      if (novel) {
        const { genre, category, workStatus } = novel;
        if (genre) {
          this.genre = genreList.find((item) => item.id === genre) ?? null;
        }
        if (category) {
          this.category = categoryList.filter((item) => category.includes(item.id));
        }
        if (workStatus) {
          this.workStatus =
            workStatusList.find((x) => x.id === workStatus) ?? workStatusList.find((x) => x.id === "inProgress")!;
        }
        // エージェントに未同意状態で、作品完了にした場合ポップアップを表示
        if (this.isAgentFeatureNotAgreed && this.$route.query.workStatus === "completed") {
          const agentPromotionDialog = new Dialog(AgentPromotionDialog);
          agentPromotionDialog.show({ novelTitle: novel.title, useWorkCompletedDesign: true });
        }
      }

      try {
        const results = await new UserClient().checkConnectedOtherServices();
        const { nolaNovel } = results;
        if (nolaNovel) {
          this.hasNolaNovelAccount = nolaNovel.result ?? false;
        }
      } catch (e) {
        // 特にエラーでも何もしない
      }
    },

    // event handle (button, checkbox, etc)

    deleteButtonClick() {
      const { novel, showNovelDeleteConfirmDialog } = this;
      if (!novel) {
        return;
      }

      const { title } = novel;
      showNovelDeleteConfirmDialog(title);
    },
    delinkNolaNovelButtonClick() {
      const { novelIdFromNolaNovel, showNolaNovelDelinkConfirmDialog } = this;
      if (!novelIdFromNolaNovel) {
        return;
      }

      showNolaNovelDelinkConfirmDialog(novelIdFromNolaNovel);
    },
    postToNolaNovelButtonClick(isPost) {
      const { showNolaNovelListDialog, novelIdFromNolaNovel, showExportConfirmDialog } = this;

      if (novelIdFromNolaNovel) {
        showExportConfirmDialog(novelIdFromNolaNovel);
        return;
      }

      showNolaNovelListDialog(isPost);
    },

    // view

    async showNolaNovelListDialog(isPost) {
      const { novelId, hasNolaNovelAccount } = this;

      if (!hasNolaNovelAccount) {
        const introduction = new Dialog(NolaNovelIntroduction);
        introduction.show();
        return;
      }

      const novelListDialog = new Dialog(NovelListDialog);
      const data: NovelListDialogProps = {
        novelId,
        isPost,
      };

      const result = await novelListDialog.show(data);
      if (result) {
        const completeDialog = new Dialog(CupertinoAlertDialog);
        const data: CupertinoAlertDialogProps = {
          title: "連携が完了しました",
          content: "今後はこの作品・エピソードをNolaから直接Nolaノベルに投稿する際は、自動で作品が選択されます。",
          action: "閉じる",
        };
        completeDialog.show(data);
      }

      const { initialize } = this;
      initialize();
    },
    async showExportConfirmDialog(id) {
      const { novel, navigateToNolaNovelEditPage } = this;
      if (!novel) {
        return;
      }

      const confirmDialog = new Dialog(NolaNovelUpdateConfirm);
      const data: NolaNovelUpdateConfirmProps = {
        id,
        content: `この作品の内容（あらすじ等）を、Nolaノベル上に投稿された連携済み作品に上書きしてよろしいですか？`,
      };
      const result = await confirmDialog.show(data);
      if (result) {
        navigateToNolaNovelEditPage(id);
      }
    },
    async showNolaNovelDelinkConfirmDialog(id) {
      const { novelId } = this;
      const deleteDialog = new Dialog(NolaNovelDelinkConfirm);
      const data: NolaNovelDelinkConfirmProps = {
        novelId,
        nolaNovelId: id,
      };

      const result = await deleteDialog.show(data);
      if (result) {
        const completeDialog = new Dialog(CupertinoAlertDialog);
        const data: CupertinoAlertDialogProps = {
          title: "連携を解除しました",
          content: "再度連携を行う場合は、「投稿する」あるいは「連携する」ボタンを選択してください。",
          action: "閉じる",
        };
        completeDialog.show(data);
      }

      const { initialize } = this;
      initialize();
    },
    async showNovelDeleteConfirmDialog(title) {
      const { novelId, novelIdFromNolaNovel } = this;
      const confirmDialog = new Dialog(NovelDeleteConfirm);
      const data: NovelDeleteConfirmProps = {
        novelId,
        nolaNovelId: novelIdFromNolaNovel,
        title,
      };

      const result = await confirmDialog.show(data);

      if (result) {
        this.$notify({
          title: "削除しました",
          type: "error",
        });

        this.$router.push({ name: "home" });
      }
    },
    async navigateToNolaNovelEditPage(id: string) {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const { novelId } = this;

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);

      const updateNovelUrl = await service.genUpdateNovelUrl(id, novelId);
      window.open(
        createUrlWithUtmParams(updateNovelUrl, NolaPageName.Detail, NolaItemId.ConfirmOverwriteNovelButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
  },
});
