
import Vue from "vue";
import AgentCampaignNovelListItem from "@/components/molecules/AgentCampaignNovelListItem.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";
import { Novel } from "@/lib/models";

export default Vue.extend({
  components: {
    AgentCampaignNovelListItem,
    MaskedLoading,
  },
  data() {
    return {
      selectedNovelIds: [] as string[],
      loading: false,
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
    };
  },
  computed: {
    targetNovels() {
      return this.$store.getters["novelModule/novels"].filter((i: Novel) => i.title !== "はじめてのNola");
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        // 選択中の作品を一括（並列処理）でエージェント連携する
        await Promise.all(
          this.selectedNovelIds.map((novelId: string) =>
            this.$store.dispatch("novelModule/requestNolaAgentForNovel", {
              novelId,
              campaignId: this.$route.params.campaignId,
            })
          )
        );

        // 応募完了画面に遷移
        this.$emit("complete");

        // アナリティクス送信
        this.selectedNovelIds.forEach((novelId: string) => {
          this.nolaAnalytics.logButtonEvent(NolaItemId.AgentLink, novelId);
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
