var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isVisible
    ? _c(
        "div",
        {
          staticClass: "agent-feature-promotion-banner",
          on: { click: _setup.onClickBanner },
        },
        [
          _c("div", { staticClass: "banner-content" }, [
            _c("img", {
              staticClass: "banner-icon",
              attrs: { src: _vm.detail.imageUrl },
            }),
            _c("p", {
              staticClass: "banner-text",
              domProps: { innerHTML: _vm._s(_vm.detail.text) },
            }),
          ]),
          _c(_setup.CloseCircleOutlineIcon, {
            staticClass: "close-button",
            attrs: { size: 14 },
            on: { click: _setup.closeBanner },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }