
import Vue from "vue";
import Editor, { IEditorEmitArgs } from "@/components/molecules/Editor.vue";
import ManuscriptPreview from "@/components/organisms/ManuscriptPreview.vue";
import TextVerticalIcon from "@/components/atoms/TextVerticalIcon.vue";
import DropupMenu, { Item } from "@/components/molecules/DropupMenu.vue";
import { Manuscript, ManuscriptSetting, Novel, Plan, SearchTextIndex } from "@/lib/models";
import clone from "lodash/cloneDeep";
// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { downloadPublicImage } from "@/lib/s3";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
import { UserClient } from "@/lib/clients";
// dialog
import { Dialog } from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";
import { showOkCancelDialog, openManuscriptEditorMenuDialog } from "@/lib/dialog";
import EpisodeListDialog, { EpisodeListDialogProps } from "@/components/ui/nolaNovel/EpisodeListDialog.vue";
import NolaNovelIntroduction from "@/components/ui/nolaNovel/NolaNovelIntroduction.vue";
import CupertinoAlertDialog, { CupertinoAlertDialogProps } from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import NolaNovelUpdateConfirm, {
  NolaNovelUpdateConfirmProps,
} from "@/components/ui/nolaNovel/NolaNovelUpdateConfirm.vue";
import EpisodeDelinkConfirm, { EpisodeDelinkConfirmProps } from "@/components/ui/nolaNovel/EpisodeDelinkConfirm.vue";
import ProofreadingConfirmationDialog from "@/components/ui/ProofreadingConfirmationDialog.vue";
import AutoIndentConfirmationDialog from "@/components/ui/AutoIndentConfirmationDialog.vue";
import UndoIcon from "icons/ArrowULeftTop.vue";
import RedoIcon from "icons/ArrowURightTop.vue";
import RubyDialog, { RubyDialogProps } from "@/components/ui/RubyDialog.vue";
import EmphasisMarkDialog, { EmphasisMarkDialogProps } from "@/components/ui/EmphasisMarkDialog.vue";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import ManuscriptHeader from "@/components/molecules/ManuscriptHeader.vue";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";
import AgentPromotionDialog from "@/components/ui/AgentPromotionDialog.vue";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";
import { useAgentStore, AgentStore } from "@/stores/agent";

const contentMaxLenth = 100000;
const CONTENT_LENGTH_OVER_50000 = 50000;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Editor,
    ManuscriptPreview,
    TextVerticalIcon,
    DropupMenu,
    ProofreadingConfirmationDialog,
    AutoIndentConfirmationDialog,
    UndoIcon,
    RedoIcon,
    ManuscriptHeader,
  },

  props: {
    novelId: String,
    manuscriptKey: String,
  },

  data() {
    const { onSelectCountType } = this as any;

    const countTypeList: Item[] = [
      {
        name: "全文字数",
        value: "onlyCharacter",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "空白を含む全文字数",
        value: "addBlank",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "改行を含む全文字数",
        value: "addNewLine",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "空白/改行を含む全文字数",
        value: "all",
        callback() {
          onSelectCountType(this.value);
        },
      },
    ];

    return {
      title: "",
      preTitle: "",
      content: "",
      preContent: "",
      saved: false,
      countTypeList,
      hasNolaNovelAccount: false,
      defaultColor: "#a0a0a0",
      activeColor: "#5698e1",
      canUndo: false,
      canRedo: false,
      unbindShortcutKeys: null,
      agentStore: useAgentStore(),
    };
  },

  async created() {
    const { initialize } = this;
    initialize();
  },

  beforeDestroy() {
    // コンポーネントが破棄される前にショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },

  destroyed() {
    this.initializeSearchState();
  },

  watch: {
    saveTimeout() {
      if (this.saveTimeout) {
        this.save();
        this.$store.dispatch("manuscriptModule/stopSaveTimeout");
      }
    },
    content: {
      handler() {
        this.setModified(this.changed);
        this.updateSearchState();
      },
      immediate: true,
    },
    searchText: {
      handler() {
        this.updateSearchState();
      },
      immediate: true,
    },
    enabledSearch(value: boolean) {
      if (!value) return;
      const { searchTargetIndex, searchTextIndexList, scrollToTargetText, focusInputSearch } = this;
      const { from } = searchTextIndexList[searchTargetIndex];
      scrollToTargetText(from);
      focusInputSearch();
      this.$store.dispatch("manuscriptModule/toggleEnabledSearch");
    },
    enabledReplaceSingle(value: boolean) {
      const {
        searchTextIndexList,
        searchTargetIndex,
        replaceText,
        scrollToTargetText,
        selectTargetText,
        executeReplaceText,
      } = this;

      if (value) {
        const { from, to } = searchTextIndexList[searchTargetIndex];
        executeReplaceText(from, to);
        this.$store.dispatch("manuscriptModule/toggleEnabledReplaceSingle");

        scrollToTargetText(from);
        selectTargetText(from, from + replaceText.length);
      }
    },
    async enabledReplaceAll(value: boolean) {
      const { searchText, replaceText } = this;

      if (value) {
        const replacedContent = this.content.replaceAll(searchText, replaceText);
        this.content = replacedContent; // WARNING: contentを直接変更しているため、undoが効かなくなる
        this.$store.dispatch("manuscriptModule/toggleEnabledReplaceAll");
      }
    },
    async restoredContent(value: string | null) {
      if (typeof value !== "string") return;

      // contentに反映する前に、現在の変更を保存する
      if (this.changed) await this.save();

      this.content = value;
      this.$store.dispatch("manuscriptModule/initializeRestoredContent");
    },
    isManuscriptUnsavedAgentNavigation: {
      handler(newValue) {
        if (newValue) {
          this.save();
        }
      },
    },
    calculateTotalNovelContentLength: {
      handler(newValue) {
        this.$store.commit("novelModule/setNovelContentLength", newValue);
      },
    },
  },

  computed: {
    novel() {
      const { novelId } = this;
      const { getters } = this.$store;
      const novel = getters["novelModule/novel"](novelId) as Novel | undefined;
      return novel;
    },
    manuscript() {
      const { getters } = this.$store;
      return getters["manuscriptModule/manuscript"](this.manuscriptKey);
    },
    changed() {
      return this.preTitle !== this.title || this.preContent !== this.content;
    },
    exceeded() {
      return this.contentLength > contentMaxLenth;
    },
    contentLength() {
      let rubyLength = 0;
      const content = clone(this.content) as string;

      // eslint-disable-next-line no-control-regex
      const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
      const textWithRuby = content.match(rubyRegExp);
      if (textWithRuby) {
        textWithRuby.forEach((text) => {
          const onlyRubyRegExp = new RegExp("《.*?》$");
          const textOnlyRuby = text.match(onlyRubyRegExp);
          rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1
        });
      }

      let contentLength = 0;
      switch (this.countType) {
        case "addBlank":
          contentLength = content.replace(/\n/g, "").length;
          break;
        case "addNewLine":
          contentLength = content.replace(/[^\S\n]/g, "").length;
          break;
        case "all":
          contentLength = content.length;
          break;
        case "onlyCharacter":
        default:
          contentLength = content.replace(/\s+|\n/g, "").length;
          break;
      }

      return contentLength - rubyLength;
    },
    pageCount() {
      const content = clone(this.content) as string;

      /** ルビを除外する */
      const splittedContent = content.split("\n");
      const removedRubyContent = splittedContent.map((content) => {
        let result = content;
        // eslint-disable-next-line no-control-regex
        const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
        const textWithRuby = content.match(rubyRegExp);
        if (textWithRuby) {
          textWithRuby.forEach((text: string) => {
            let replaceText = text;
            const parentRegExp = new RegExp("[|｜]");
            replaceText = replaceText.replace(parentRegExp, "");
            replaceText = replaceText.replace("《.*?》", "");
            result = content.replace(text, replaceText);
          });
        }
        return result;
      });

      /** 原稿の設定情報を取得 */
      const { wordCountOnVertical, lineCountPerPage } = this.manuscriptSetting as ManuscriptSetting;

      /** 行数を計算する */
      let lineCount = 0;
      removedRubyContent.forEach(
        (content) => (lineCount += content ? Math.ceil(content.length / wordCountOnVertical) : 1)
      );
      return Math.ceil(lineCount / lineCountPerPage);
    },
    onlyCharacterWordCount() {
      const content = clone(this.content) as string;

      let rubyLength = 0;
      // eslint-disable-next-line no-control-regex
      const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
      const textWithRuby = content.match(rubyRegExp);
      if (textWithRuby) {
        textWithRuby.forEach((text) => {
          const onlyRubyRegExp = new RegExp("《.*?》$");
          const textOnlyRuby = text.match(onlyRubyRegExp);
          rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1
        });
      }

      const contentLength = content.replace(/\s+|\n/g, "").length;

      return contentLength - rubyLength;
    },
    isShowPreview() {
      const { $store, isFree } = this;
      const { getters } = $store;

      const isShowPreview = getters["manuscriptModule/isShowPreviewOnManuscriptEditor"];

      return !isFree && isShowPreview;
    },
    manuscriptList() {
      const key = this.manuscriptKey;
      const { title, content } = this;
      const manuscriptObject: Manuscript = {
        key,
        title,
        content,
      };
      return [manuscriptObject];
    },
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
    countType() {
      return this.$store.getters["manuscriptModule/countType"];
    },
    countTypeName() {
      const countTypeValue = this.countType;
      const countType = (this.countTypeList as Item[]).find((countType) => countType.value === countTypeValue);
      const countTypeName = countType ? countType.name : "";

      /** 原稿の設定情報を取得 */
      const { wordCountOnVertical, lineCountPerPage } = this.manuscriptSetting as ManuscriptSetting;

      return `${countTypeName}（縦${wordCountOnVertical}×横${lineCountPerPage}）`;
    },
    wordCountInfo() {
      const { contentLength } = this;
      const { pageCount } = this;

      return `${contentLength}字（${pageCount}P換算）`;
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId) as ManuscriptSetting;
    },
    saveTimeout() {
      return this.$store.getters["manuscriptModule/saveTimeout"];
    },
    saveStatus() {
      const { autoSave, saved } = this;

      if (autoSave) {
        return saved ? "自動保存しました" : "自動保存設定中";
      }
      return saved ? "保存しました" : "";
    },
    lastModified() {
      return this.manuscript && this.manuscript.lastModified ? `最終保存：${this.manuscript.lastModified}` : "";
    },
    autoSave() {
      return this.$store.getters["manuscriptModule/autoSave"];
    },
    textareaElement() {
      return document.getElementById("textarea") as HTMLTextAreaElement;
    },
    enabledSearchReplace() {
      return this.$store.getters["manuscriptModule/enabledSearchReplace"];
    },
    enabledReplace() {
      return this.$store.getters["manuscriptModule/enabledReplace"];
    },
    searchText() {
      return this.$store.getters["manuscriptModule/searchText"];
    },
    replaceText() {
      return this.$store.getters["manuscriptModule/replaceText"];
    },
    enabledSearch() {
      return this.$store.getters["manuscriptModule/enabledSearch"];
    },
    enabledReplaceSingle() {
      return this.$store.getters["manuscriptModule/enabledReplaceSingle"];
    },
    enabledReplaceAll() {
      return this.$store.getters["manuscriptModule/enabledReplaceAll"];
    },
    searchTargetIndex() {
      return this.$store.getters["manuscriptModule/searchTargetIndex"];
    },
    searchTotalCount() {
      return this.$store.getters["manuscriptModule/searchTotalCount"];
    },
    searchTextIndexList() {
      return this.$store.getters["manuscriptModule/searchTextIndexList"];
    },
    restoredContent() {
      return this.$store.getters["manuscriptModule/restoredContent"];
    },

    // NolaNovel

    novelIdFromNolaNovel() {
      const { novel } = this;

      if (!novel) {
        return undefined;
      }

      const { associatedData } = novel as Novel;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;

      if (!id) {
        return undefined;
      }

      return id;
    },
    episodeIdFromNolaNovel() {
      const { manuscript } = this;
      if (!manuscript) {
        return undefined;
      }

      const { associatedData } = manuscript as Manuscript;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;

      if (!id) {
        return undefined;
      }

      return id;
    },
    isContentLengthOver50000() {
      return this.calculateTotalNovelContentLength >= CONTENT_LENGTH_OVER_50000;
    },
    isAgentPopupDisplayedForNovel() {
      const { $store, novelId } = this;
      return $store.getters["generalModule/isAgentPopupDisplayedForNovel"](novelId);
    },
    isManuscriptUnsavedAgentNavigation() {
      return this.agentStore.isManuscriptUnsavedAgentNavigation;
    },
    calculateTotalNovelContentLength() {
      const manuscriptList = this.$store.getters["manuscriptModule/manuscriptList"];

      // Storeに格納されている原稿は、保存前に最新の文字数が反映されていない
      // 最新のトータル文字数を計算する為、現在開いている原稿は除外する
      const filteredManuscriptList = manuscriptList.filter(
        (manuscript: Manuscript) => manuscript.key !== this.manuscript.key
      );
      // 現在開いている原稿の文字数から空白や改行を除いた文字数を加算
      let totalContentLength = this.content.replace(/\s+|\n/g, "").length;

      filteredManuscriptList.forEach((manuscript: Manuscript) => {
        let rubyLength = 0;
        const content = clone(manuscript.content) as string;

        // eslint-disable-next-line no-control-regex
        const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
        const textWithRuby = content.match(rubyRegExp);
        if (textWithRuby) {
          textWithRuby.forEach((text) => {
            const onlyRubyRegExp = new RegExp("《.*?》$");
            const textOnlyRuby = text.match(onlyRubyRegExp);
            rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1
          });
        }

        // 空白や改行を除いた文字数をカウント
        const contentLength = content.replace(/\s+|\n/g, "").length;

        totalContentLength += contentLength - rubyLength;
      });

      return totalContentLength;
    },
  },

  methods: {
    // initialize

    async initialize() {
      const { manuscript } = this;
      const { title, content } = manuscript;
      this.title = title || "";
      this.content = content || "";
      this.preTitle = String(this.title);
      this.preContent = String(this.content);

      // set RecordWordCount
      this.$store.commit("manuscriptModule/setBaseWordCount", this.onlyCharacterWordCount);

      // 各ショートカットキーの有効化と解除関数の取得
      this.unbindShortcutKeys = handleShortcutKeys([
        { trigger: TriggerKey.Meta, keys: ["s"], callback: this.save },
        { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.save },
        { trigger: TriggerKey.Meta, keys: ["f"], callback: this.enableSearchReplace },
        { trigger: TriggerKey.Ctrl, keys: ["f"], callback: this.enableSearchReplace },
        { trigger: TriggerKey.Meta, keys: ["h"], callback: this.enableReplace },
        { trigger: TriggerKey.Ctrl, keys: ["h"], callback: this.enableReplace },
        { keys: ["Escape", "esc"], callback: this.disableSearchReplace },
      ]);

      // 作品の執筆文字数をStoreにセット
      this.$store.commit("novelModule/setNovelContentLength", this.calculateTotalNovelContentLength);

      try {
        const results = await new UserClient().checkConnectedOtherServices();
        const { nolaNovel } = results;
        if (nolaNovel) {
          this.hasNolaNovelAccount = nolaNovel.result ?? false;
        }
      } catch (e) {
        // 特にエラーでも何もしない
      }
    },

    // execute

    async save() {
      const { novelId, title, content, manuscriptKey, changed } = this;

      if (!changed) return;

      if (this.contentLength > contentMaxLenth) {
        this.showErrorDialog(
          "本文の文字数が10万字を越えています。<br />10万字以内に編集して再度保存を実行してください。<br />※別の原稿にて続きをお書きください。"
        );

        // エラーの場合はプロモーション風バナー利用済みフラグ/isManuscriptUnsavedAgentNavigationフラグを初期化する
        this.$store.dispatch("generalModule/setUsedAgentFeaturePromoBanner", false);
        this.agentStore.setManuscriptUnsavedAgentNavigation(false);
        return;
      }

      const key = manuscriptKey;
      this.$store.dispatch("manuscriptModule/updateManuscript", {
        novelId,
        key,
        title,
        content,
        callback: async () => {
          this.preTitle = clone(title);
          this.preContent = clone(content);
          this.setModified(false);

          this.saved = true;
          setTimeout(() => (this.saved = false), 2000);

          this.$notify({
            title: "保存しました",
            type: "success",
            duration: 2000,
          });

          // 作品文字数が5万文字を超えた場合かつ、これまでエージェント機能ポップアップを
          // 表示していない作品の場合、ポップアップを表示する
          if (this.isContentLengthOver50000 && !this.isAgentPopupDisplayedForNovel) {
            // 自動保存が有効な場合、即時にポップアップを表示せず、
            // Storeに表示フラグをセットし、ホーム画面に遷移したときにポップアップを表示する
            if (this.autoSave) {
              this.$store.commit("generalModule/setAgentPopupDisplayFlagNovelId", novelId);
              return;
            }

            const agentPromotionDialog = new Dialog(AgentPromotionDialog);
            agentPromotionDialog.show({ novelTitle: this.novel!.title });
            await this.$store.dispatch("generalModule/addDisplayedAgentPopupForNovel", novelId);
          }
        },
      });
      this.$store.dispatch("manuscriptModule/incrementWritingCount", {
        novelId,
        value: this.onlyCharacterWordCount,
      });

      // 原稿未保存状態でエージェント機能プロモーションダイアログのボタンが
      // 押下された場合、エージェントイントロ画面へ遷移する
      if (this.isManuscriptUnsavedAgentNavigation) {
        this.setModified(false);
        this.$router.push({
          name: "agent",
        });
      }
    },
    async goToMultiEditor() {
      const { novelId, manuscriptKey } = this;
      if (this.isFree) {
        // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
        // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
        // const data: PremiumFeatureDialogProps = {
        //   title: "マルチ執筆モードはプレミアム機能です",
        //   text: "Nolaプレミアムにご登録いただくと【全画面表示】で【資料を見ながら】執筆が可能な『マルチ執筆モード』をご利用いただけます。執筆がリアルタイムに反映される縦書きプレビューも同時にご利用いただけますので、お手すきの際にぜひご利用いただけますと幸いです。",
        //   image: (await downloadPublicImage("shared/images/gif/manuscript_multi_editor.gif")) as string,
        // };
        // premiumFeatureDialog.show(data);

        this.$router.push({
          name: "subscriptionAnnounce",
          query: { from: "multiEditor" },
        });

        return;
      }

      this.$router.push({
        name: "multiEditor",
        params: {
          novelId,
          manuscriptKey,
        },
      });
    },
    async navigateToNolaNovelEditPage(id: string) {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const { novelId, manuscript } = this;
      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const updateEpisodeUrl = await service.genUpdateEpisodeUrl(id, novelId, manuscript.key!);
      window.open(
        createUrlWithUtmParams(updateEpisodeUrl, NolaPageName.Editor, NolaItemId.ConfirmOverwriteEpisodeButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
    executeReplaceText(from, to) {
      const { textareaElement, replaceText } = this;
      textareaElement.selectionStart = from;
      textareaElement.selectionEnd = to;
      textareaElement.focus();
      document.execCommand("insertText", false, replaceText);
    },
    initializeSearchState() {
      this.disableSearchReplace();
      this.$store.dispatch("manuscriptModule/updateSearchText", "");
      this.$store.dispatch("manuscriptModule/updateReplaceText", "");
    },
    updateSearchState() {
      const { searchText } = this;
      const searchTextIndexList: SearchTextIndex[] = [];

      if (searchText) {
        const escapedText = searchText.replaceAll(/[.*+?^${}()[\]\\]/g, "\\$&");

        // ルビなしとルビありの両方に対応する正規表現パターン
        // ルビ付き文字の部分とプレーンテキストの部分を分離して検出
        const rubyPattern = escapedText
          .split("")
          .map((char: string) => `[｜|]?${char}(?:《[^》]*》)?`)
          .join("");

        const searchRegExp = new RegExp(rubyPattern, "gu");

        let match: RegExpExecArray | null;
        // eslint-disable-next-line no-cond-assign
        while ((match = searchRegExp.exec(this.content)) !== null) {
          // 検索結果の開始インデックス
          const from = match.index;
          // 検索結果の終了インデックス
          const to = match.index + match[0].length;
          // 検索結果をリストに追加
          searchTextIndexList.push({ from, to });
        }
      }

      this.$store.dispatch("manuscriptModule/updateSearchTextIndexList", searchTextIndexList);
      this.$store.dispatch("manuscriptModule/updateSearchTargetIndex", 0);
      this.$store.dispatch("manuscriptModule/updateSearchTotalCount", searchTextIndexList.length);
    },

    // event handle

    async handleBackAction() {
      const { novelId, changed } = this;
      if (changed) {
        const yes = await showOkCancelDialog({
          title: "確認",
          content: "保存されていない変更があります。<br />変更を破棄して戻りますか？",
          okButton: "破棄する",
          cancelButton: "キャンセル",
        });
        if (!yes) {
          return;
        }
      }

      this.$router.push({ name: "editor", params: { novelId } });
    },
    onExportButtonClick(isPost) {
      const { hasNolaNovelAccount } = this;
      if (!hasNolaNovelAccount) {
        const introduction = new Dialog(NolaNovelIntroduction);
        introduction.show();
        return;
      }

      const { changed, showUnsavedConfirm } = this;
      if (changed) {
        showUnsavedConfirm();
        return;
      }

      const { episodeIdFromNolaNovel, showNolaNovelExportConfirm } = this;

      if (episodeIdFromNolaNovel) {
        showNolaNovelExportConfirm(episodeIdFromNolaNovel);
        return;
      }

      const { showNolaNovelListDialog } = this;
      showNolaNovelListDialog(isPost);
    },
    async onClickPreview() {
      if (this.isFree) {
        // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
        // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
        // const data: PremiumFeatureDialogProps = {
        //   title: "執筆時の縦書きプレビューはプレミアム機能です",
        //   text: "Nolaプレミアムにご登録いただくと、原稿の執筆時にリアルタイムで反映される『縦書きプレビュー機能』をご利用いただけます。一行辺りの文字数やページ辺りの行数も設定可能ですので、お手すきの際にぜひご活用いただだけたら幸いです。",
        //   image: (await downloadPublicImage("shared/images/gif/manuscript_vertical_writing.gif")) as string,
        // };
        // premiumFeatureDialog.show(data);

        this.$router.push({
          name: "subscriptionAnnounce",
          query: { from: "verticalWriting" },
        });

        return;
      }
      this.$store.dispatch("manuscriptModule/switchPreviewOnManuscriptEditor");
    },
    async onChangeTitle(args) {
      const { value, isComposing } = args;

      this.title = value;
      this.setModified(this.changed);

      if (this.changed && this.autoSave && !isComposing) {
        this.$store.dispatch("manuscriptModule/startSaveTimeout");
      }
    },
    async onChangeContent(args) {
      this.checkUndoRedo();

      const { value, isComposing } = args;

      this.content = value;
      this.setModified(this.changed);

      if (this.changed && this.autoSave && !isComposing) {
        this.$store.dispatch("manuscriptModule/startSaveTimeout");
      }
    },
    onStartComposition() {
      if (this.changed && this.autoSave) {
        this.$store.dispatch("manuscriptModule/stopSaveTimeout");
      }
    },
    onEndComposition() {
      if (this.changed && this.autoSave) {
        this.$store.dispatch("manuscriptModule/startSaveTimeout");
      }
    },
    onSelectCountType(countTypeValue) {
      this.$store.dispatch("manuscriptModule/selectCountType", countTypeValue);
    },
    enableSearchReplace() {
      const { $store, enabledSearchReplace } = this;

      if (!enabledSearchReplace) $store.dispatch("manuscriptModule/toggleEnabledSearchReplace");

      const selectedText = window.getSelection()!.toString();
      if (selectedText) $store.dispatch("manuscriptModule/updateSearchText", selectedText);

      // 検索ダイアログにフォーカスを設定
      this.$nextTick(() => {
        const searchInput = document.getElementById("inputSearch") as HTMLInputElement;
        if (searchInput) {
          searchInput.focus();
        }
      });
    },
    enableReplace() {
      const { $store, enabledReplace, enableSearchReplace } = this;
      enableSearchReplace();
      if (!enabledReplace) $store.dispatch("manuscriptModule/toggleEnabledReplace");
    },
    disableSearchReplace() {
      if (this.enabledSearchReplace) this.$store.dispatch("manuscriptModule/toggleEnabledSearchReplace");
    },
    scrollToTargetText(to) {
      // NOTE: 検索対象文字がtextareaの表示外にあるとき、検索対象文字までスクロールさせる
      const { textareaElement } = this;
      textareaElement.selectionStart = to;
      textareaElement.selectionEnd = to;
      textareaElement.blur();
      textareaElement.focus();
    },
    selectTargetText(from, to) {
      const { textareaElement } = this;
      textareaElement.selectionStart = from;
      textareaElement.selectionEnd = to;
      textareaElement.focus();
    },
    focusInputSearch() {
      const inputSearchElement = document.getElementById("inputSearch") as HTMLInputElement;
      inputSearchElement.focus();
    },

    // view

    showUnsavedConfirm() {
      const completeDialog = new Dialog(CupertinoAlertDialog);
      const data: CupertinoAlertDialogProps = {
        title: "未保存の内容があります",
        content: "未保存のデータがあります。保存を実行してから投稿することが可能です。",
        action: "閉じる",
      };
      completeDialog.show(data);
    },
    async showMenu() {
      openManuscriptEditorMenuDialog();
    },
    showErrorDialog(message: string) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },

    async showNolaNovelExportConfirm(id) {
      const confirmDialog = new Dialog(NolaNovelUpdateConfirm);
      const data: NolaNovelUpdateConfirmProps = {
        id,
        content: `この原稿を、Nolaノベル上に投稿された連携済みエピソードに上書きしてよろしいですか？`,
      };
      const result = await confirmDialog.show(data);
      const { navigateToNolaNovelEditPage } = this;
      if (result) {
        navigateToNolaNovelEditPage(id);
      }
    },
    async showNolaNovelListDialog(isPost: boolean) {
      const { novelId, manuscriptKey, novelIdFromNolaNovel } = this;
      const novelListDialog = new Dialog(EpisodeListDialog);
      const data: EpisodeListDialogProps = {
        novelId,
        manuscriptId: manuscriptKey,
        isPost,
        novelIdFromNolaNovel,
      };

      const result = await novelListDialog.show(data);
      if (result) {
        const completeDialog = new Dialog(CupertinoAlertDialog);
        const data: CupertinoAlertDialogProps = {
          title: "連携が完了しました",
          content: "今後はこの原稿をNolaからNolaノベルに投稿する際は、自動でエピソードが選択されます。",
          action: "閉じる",
        };
        completeDialog.show(data);
      }

      const { initialize } = this;
      initialize();
    },
    async showNolaNovelDelinkConfirmDialog(id) {
      const { novelId, manuscriptKey } = this;
      const deleteDialog = new Dialog(EpisodeDelinkConfirm);
      const data: EpisodeDelinkConfirmProps = {
        novelId,
        manuscriptId: manuscriptKey,
        episodeId: id,
      };

      const result = await deleteDialog.show(data);
      if (result) {
        const completeDialog = new Dialog(CupertinoAlertDialog);
        const data: CupertinoAlertDialogProps = {
          title: "連携を解除しました",
          content:
            "再度連携を行う場合は、Nolaノベルアイコンまたはメニュー内の「Nolaノベルと連携する」を選択してください。",
          action: "閉じる",
        };
        completeDialog.show(data);
      }

      const { initialize } = this;
      initialize();
    },
    undo() {
      document.execCommand("undo", false, undefined);
    },
    redo() {
      document.execCommand("redo", false, undefined);
    },
    checkUndoRedo() {
      this.canUndo = document.queryCommandEnabled("undo");
      this.canRedo = document.queryCommandEnabled("redo");
    },
    brackets() {
      const { textareaElement } = this;
      textareaElement.focus();
      const { selectionStart } = textareaElement;
      const selectedText = window.getSelection()!.toString();
      document.execCommand("insertText", false, `「${selectedText}」`);

      // NOTE: 文字選択をしていない場合、括弧の間にカーソルを移動
      if (!selectedText) {
        textareaElement.selectionStart = selectionStart + 1;
        textareaElement.selectionEnd = selectionStart + 1;
      }
    },
    ellipsis() {
      const { textareaElement } = this;
      textareaElement.focus();
      document.execCommand("insertText", false, "……");
    },
    space() {
      const { textareaElement } = this;
      textareaElement.focus();
      document.execCommand("insertText", false, "　");
    },
    dash() {
      const { textareaElement } = this;
      textareaElement.focus();
      document.execCommand("insertText", false, "――");
    },
    async ruby() {
      const { textareaElement } = this;
      textareaElement.focus();
      const selectedText = window.getSelection()!.toString();

      const rubyDialog = new Dialog(RubyDialog);
      const data: RubyDialogProps = {
        selectedText,
        negative: "キャンセル",
        positive: "入力する",
      };
      const result = await rubyDialog.show(data);

      textareaElement.focus();

      if (!result) return;
      document.execCommand("insertText", false, result);
    },
    async emphasisMark() {
      const { textareaElement } = this;
      textareaElement.focus();
      const selectedText = window.getSelection()!.toString();

      const emphasisMarkDialog = new Dialog(EmphasisMarkDialog);
      const data: EmphasisMarkDialogProps = {
        selectedText,
        negative: "キャンセル",
        positive: "入力する",
      };
      const result = await emphasisMarkDialog.show(data);

      textareaElement.focus();

      if (!result) return;
      document.execCommand("insertText", false, result);
    },
    handleCommentIconClick() {
      this.$emit("commentIconClicked");
    },
  },
});

interface Props {
  novelId: string;
  manuscriptKey: string;
}

interface Data {
  title: string;
  preTitle: string;
  content: string;
  preContent: string;
  saved: boolean;
  countTypeList: Item[];
  hasNolaNovelAccount: boolean;
  defaultColor: string;
  activeColor: string;
  canUndo: boolean;
  canRedo: boolean;
  unbindShortcutKeys: (() => void) | null;
  agentStore: AgentStore;
}

interface Methods {
  initialize(): void;

  save(): Promise<void>;
  handleBackAction(): Promise<void>;
  goToMultiEditor(): Promise<void>;
  navigateToNolaNovelEditPage(id: string): void;
  executeReplaceText(from: number, to: number): void;
  initializeSearchState(): void;
  updateSearchState(): void;

  onClickPreview(): void;
  onSelectCountType(countTypeValue: any): void;
  onChangeTitle(args: IEditorEmitArgs): Promise<void>;
  onChangeContent(args: IEditorEmitArgs): Promise<void>;
  onStartComposition(): void;
  onEndComposition(): void;
  onExportButtonClick(isPost: boolean): void;
  enableSearchReplace(): void;
  enableReplace(): void;
  disableSearchReplace(): void;
  scrollToTargetText(to: number): void;
  selectTargetText(from: number, to: number): void;
  focusInputSearch(): void;

  showUnsavedConfirm(): void;
  showMenu(): Promise<void>;
  showErrorDialog(message: string): void;
  showNolaNovelExportConfirm(id: string): void;
  showNolaNovelListDialog(isPost: boolean): void;
  showNolaNovelDelinkConfirmDialog(id: string): void;
  undo(): void;
  redo(): void;
  checkUndoRedo(): void;
  brackets(): void;
  ellipsis(): void;
  space(): void;
  dash(): void;
  ruby(): void;
  emphasisMark(): void;
  handleCommentIconClick(): void;
}

interface Computed {
  novel?: Novel;
  manuscript: Manuscript;
  changed: boolean;
  exceeded: boolean;
  contentLength: number;
  pageCount: number;
  onlyCharacterWordCount: number;
  isShowPreview: boolean;
  manuscriptList: Manuscript[];
  theme: string;
  countType: string;
  countTypeName: string;
  wordCountInfo: string;
  plan: Plan;
  isFree: boolean;
  manuscriptSetting: ManuscriptSetting;
  saveTimeout: boolean;
  saveStatus: string;
  lastModified: string;
  autoSave: boolean;
  textareaElement: HTMLTextAreaElement;
  enabledSearchReplace: boolean;
  enabledReplace: boolean;
  searchText: string;
  replaceText: string;
  enabledSearch: boolean;
  enabledReplaceSingle: boolean;
  enabledReplaceAll: boolean;
  searchTargetIndex: number;
  searchTotalCount: number;
  searchTextIndexList: SearchTextIndex[];
  restoredContent: string | null;
  isContentLengthOver50000: boolean;
  isAgentPopupDisplayedForNovel: boolean;
  isManuscriptUnsavedAgentNavigation: boolean;
  calculateTotalNovelContentLength: number;

  // NolaNovel
  novelIdFromNolaNovel?: string;
  episodeIdFromNolaNovel?: string;
}
