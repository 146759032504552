
import Vue from "vue";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";
import AgentIntro from "@/components/organisms/AgentIntro.vue";
import AgentEntryForm from "@/components/organisms/event/AgentEntryForm.vue";
import AgentEntryComplete from "@/components/organisms/event/AgentEntryComplete.vue";
import { User } from "@/lib/models";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

const Steps = {
  Agreement: "Agreement",
  Entry: "Entry",
  Complete: "Complete",
} as const;
type StepType = typeof Steps[keyof typeof Steps];

export default Vue.extend<Data, Methods, Computed, Props>({
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    AgentIntro,
    AgentEntryForm,
    AgentEntryComplete,
  },
  props: {
    id: String,
    contestId: String,
  },
  async created() {
    // エージェント未同意の場合は同意画面を表示
    if (!this.user.agentStartedAt) {
      this.currentStep = Steps.Agreement;
    }

    const contestRequest = await axios.get(`/contest/${this.id}`);
    if (contestRequest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }
    this.$store.dispatch("novelModule/initialize");
  },
  data() {
    return {
      Steps,
      currentStep: Steps.Entry,
      entryData: {
        entryItems: {},
      },
      errors: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters["userModule/user"];
    },
  },
  methods: {
    async updateStep(step) {
      this.currentStep = step;
      if (this.$refs.eventAgentEntry) {
        (this.$refs.eventAgentEntry as HTMLElement).scrollTop = 0;
      }
    },
  },
  watch: {
    user: {
      handler(val) {
        // エージェント利用開始を検知してエントリー画面に遷移
        if (val.agentStartedAt) {
          this.updateStep(Steps.Entry);
        }
      },
      immediate: true,
    },
  },
});

interface Props {
  /** ID assigned by microCMS */
  id: string;
  /** ID assigned by NolaContest */
  contestId: string;
}

interface Data {
  Steps: typeof Steps;
  currentStep: StepType;
}

interface Computed {
  user: User;
}

interface Methods {
  updateStep(step: StepType): Promise<void>;
}
