var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "frame", staticClass: "frame" }, [
    _vm.isCampaign
      ? _c("div", { staticClass: "campaign-header" }, [
          _vm._v(" Nolaエージェント機能の利用を開始して"),
          _c("br"),
          _vm._v(" キャンペーン応募を完了しましょう "),
        ])
      : _vm._e(),
    _c(
      "div",
      { ref: "container", staticClass: "container" },
      [
        _vm.isCampaign
          ? [
              _c("div", { staticClass: "top-info" }, [
                _vm._v("＼ワンタップで商業化をサポート／"),
              ]),
              _c("h1", { staticClass: "top-title" }, [
                _vm._v("Nolaエージェント機能とは？"),
              ]),
              _c("img", {
                staticClass: "campaign-image",
                attrs: { src: require("@/assets/img/agent/handshake.webp") },
              }),
            ]
          : [
              _c("div", { staticClass: "top-info" }, [
                _vm._v("＼未完結でもOK・ワンタップで完了／"),
              ]),
              _vm._m(0),
            ],
        _vm._m(1),
        _vm.isIntro && _vm.isLoadCMS && _vm.isAgentFeatureNotAgreed
          ? _c("div", { staticClass: "item-box" }, [
              _c(
                "button",
                {
                  staticClass: "button submit",
                  on: { click: _vm.ctaTopClicked },
                },
                [_vm._v(_vm._s(_vm.detail.ctaTopBtnText))]
              ),
            ])
          : _vm._e(),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _c("div", { staticClass: "item-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("実績")]),
          _vm._m(5),
          _c("div", { staticClass: "achieve" }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(6),
              _c("img", {
                staticClass: "company",
                attrs: { src: _vm.detail.partnerCompanyImage.url },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "item-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("注意事項")]),
          _c("div", { staticClass: "caution" }, [
            _c("div", {
              staticClass: "card",
              domProps: {
                innerHTML: _vm._s(_vm.$sanitize(_vm.detail.cautions)),
              },
            }),
          ]),
        ]),
        [
          (_vm.isIntro || _vm.isCampaign) &&
          _vm.isLoadCMS &&
          _vm.isAgentFeatureNotAgreed
            ? _c("div", { staticClass: "item-box no-margin" }, [
                _c("div", { staticClass: "agreement" }, [
                  _c(
                    "button",
                    {
                      ref: "submitButton",
                      staticClass: "button submit",
                      on: { click: _vm.submit },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.detail.ctaBottomBtnText) + " "),
                      _c("span", { staticClass: "arrow" }, [_vm._v("＞")]),
                    ]
                  ),
                  _vm._m(7),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "footer-container" },
            [
              _vm.detail.showLinkedNovelCount
                ? _c("FadeFooterButton", {
                    attrs: {
                      isVisibleFooter: _vm.isVisibleFooter,
                      linkedNovelCount: _vm.detail.linkedNovelCount,
                    },
                    on: { "footer-action": _vm.handleFooterAction },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        _c("img", {
          ref: "end",
          staticClass: "cat-hr",
          attrs: { src: require("@/assets/img/event/contest/cat_hr.png") },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h1", { staticClass: "top-title" }, [
      _vm._v("Nolaエージェント機能を使って"),
      _c("br"),
      _vm._v("商業化の可能性を拡げよう"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "intro" }, [
      _vm._v(" Nolaエージェント機能は、作家の手元にある作品の"),
      _c("span", { staticClass: "text-orange font-bold" }, [
        _vm._v("商業化を営業面でサポートする機能"),
      ]),
      _vm._v("です。"),
      _c("span", { staticClass: "important" }, [
        _vm._v("本機能の利用をONにした作品のみ"),
      ]),
      _vm._v(
        "をエージェントチームが確認し、商業出版につながりそうな出版社に営業提案します。なお、機能利用による"
      ),
      _c("span", { staticClass: "important" }, [
        _vm._v("作品の商業化権の独占は行いません。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "item-box" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("商業化に繋ぐ2つの取り組み"),
      ]),
      _c("div", { staticClass: "commercial" }, [
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/agent.png"),
              alt: "agent",
            },
          }),
          _c("div", { staticClass: "card-title" }, [
            _vm._v("Nolaエージェントが"),
            _c("br"),
            _vm._v("編集部に直接打診"),
          ]),
          _c("div", { staticClass: "card-content" }, [
            _vm._v(
              " 提出作品を拝読し、商業作品としての提案が可能な場合は、作品のジャンルに合った企業へ直接商業化の打診をいたします。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/matching.png"),
              alt: "matching",
            },
          }),
          _c("div", { staticClass: "card-title" }, [
            _vm._v("編集者の掲示板の"),
            _c("br"),
            _vm._v("連携企業とマッチング"),
          ]),
          _c("div", { staticClass: "card-content" }, [
            _vm._v(
              " Nolaノベルの『編集部の掲示板』の導入企業のみが閲覧できる形式で、各社の募集条件と一致する作品のマッチングを支援します。 "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "item-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("エージェントの流れ")]),
      _c("div", { staticClass: "flow" }, [
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/flow1.png"),
              alt: "flow1",
            },
          }),
          _c("div", { staticClass: "text-block" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("作品ごとにエージェント機能をON"),
            ]),
            _c("div", { staticClass: "card-content" }, [
              _vm._v(
                " 機能利用の有無は作品単位で設定できます。設定がONになっていない作品を勝手に閲覧することはございませんのでご安心ください。 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/flow2.png"),
              alt: "flow2",
            },
          }),
          _c("div", { staticClass: "text-block" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("エージェント可能な作品は編集部へ提案"),
            ]),
            _c("div", { staticClass: "card-content" }, [
              _vm._v(
                " 機能利用がONになっている作品のみをエージェントチームが確認し、商業提案可能な場合は各種出版社へ作品の共有・打診を行います。 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/flow3.png"),
              alt: "flow3",
            },
          }),
          _c("div", { staticClass: "text-block" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("オファーが届いたら商業化を検討"),
            ]),
            _c("div", { staticClass: "card-content" }, [
              _vm._v(
                " 商業オファーに繋がった場合は、Nolaの登録メールアドレスまでご連絡いたします。諸条件を確認し、商業化の可否をご検討ください。 "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "item-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("3つの特徴")]),
      _c("div", { staticClass: "desc" }, [
        _vm._v(" 作品データをワンタップで共有開始できるので、"),
        _c("span", { staticClass: "text-orange" }, [
          _vm._v("手間なく商業化の可能性"),
        ]),
        _vm._v("を拡げられます。 "),
      ]),
      _c("div", { staticClass: "feature" }, [
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/feature1.png"),
              alt: "feature1",
            },
          }),
          _c("div", { staticClass: "text-block" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("ワンタップで"),
              _c("br"),
              _vm._v("作品を提出が完了"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/feature2.png"),
              alt: "feature2",
            },
          }),
          _c("div", { staticClass: "text-block" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("設定をONにして"),
              _c("br"),
              _vm._v("あとは待つだけ"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "agent-icon",
            attrs: {
              src: require("@/assets/img/agent/feature3.png"),
              alt: "feature3",
            },
          }),
          _c("div", { staticClass: "text-block" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v("非独占なので"),
              _c("br"),
              _vm._v("他サイトの掲載OK"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "desc" }, [
      _vm._v(" すでに"),
      _c("span", { staticClass: "text-orange" }, [_vm._v("20社以上")]),
      _vm._v("の企業の皆さまと連携し、作品の商業化を支援しています。 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "achieve-desc" }, [
      _c("div", [
        _c("span", { staticClass: "head" }, [_vm._v("取引企業")]),
        _c("br"),
        _c("span", { staticClass: "foot" }, [_vm._v("(一部抜粋)")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "caution-footer" }, [
      _vm._v(
        " ※ 次ページに進んだ場合も、必ずしも作品をご提出いただく必要はございません。"
      ),
      _c("br"),
      _vm._v(" ご安心いただければ幸いです。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }