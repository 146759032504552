
import Vue from "vue";
import AgentNotifications from "@/components/organisms/AgentNotifications.vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { AgentNotifications },
});
