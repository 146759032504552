
import Vue, { PropType } from "vue";
import { ContestEntryItemModel, NolaContest } from "@/lib/models/nolaContest";
import ContestEntryItem from "@/components/molecules/ContestEntryItem.vue";
import InputTextV2 from "@/components/atoms/InputTextV2.vue";
import SelectBoxV2, { Option } from "@/components/atoms/SelectBoxV2.vue";
import CharacterItem from "@/components/atoms/CharacterItem.vue";
import { Dialog } from "@/lib/utils";
import ContestEntryDataDialog, { ContestEntryDataDialogProps } from "@/components/ui/ContestEntryDataDialog.vue";
import { Character, isSubPlot, Manuscript, Novel, Plot, SubPlot } from "@/lib/models";
import { ValidationError } from "@/pages/EventContestEntry.vue";
import { compareAsc } from "date-fns";
import ContestNolaNovelSelectItem from "@/components/molecules/ContestNolaNovelSelectItem.vue";
import NolaNovelCheckboxGroups from "@/components/molecules/NolaNovelCheckboxGroups.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import { UserClient } from "@/lib/clients";

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    ContestEntryItem,
    InputTextV2,
    SelectBoxV2,
    CharacterItem,
    ContestNolaNovelSelectItem,
    NolaNovelCheckboxGroups,
  },
  props: {
    value: Object,
    contest: Object as PropType<NolaContest>,
    contestImage: String as PropType<string>,
    isConfirm: Boolean as PropType<boolean>,
    errors: {
      type: Array as PropType<ValidationError[]>,
      default: () => [],
      required: false,
    },
  },

  async created() {
    if (this.contest.platform === "nolaNovel") {
      // NolaノベルのコンテストでNolaノベルに未登録の場合はイベントページに遷移
      const userClient = new UserClient();
      const results = await userClient.checkConnectedOtherServices();
      if (!results.nolaNovel.result) {
        this.$router.push(`/event/contest/${this.contest.id}`);
      }
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    contestEntryItems() {
      const { entryItems } = this.contest;
      const entryItemsWithCategory =
        (this.internalValue.category &&
          this.contest.categories!.find((category) => category.id === this.internalValue.category.id)!.entryItems) ||
        [];
      const mergedItems = [...entryItems, ...entryItemsWithCategory] as ContestEntryItemModel[];
      const sortedItems = mergedItems.sort((a, b) => (a.order || 0) - (b.order || 0));
      return sortedItems;
    },
    novels() {
      return this.$store.getters["novelModule/novels"];
    },
    novel() {
      const { id } = this.internalValue.novel;
      return this.novels.find((novel) => novel.novelId === id);
    },
    plots() {
      const defaultPlot = this.$store.getters["plotModule/plots"] as Plot | null;
      if (!defaultPlot) return [];
      const subPlots = this.$store.getters["plotModule/subPlots"] as SubPlot[];
      return [defaultPlot, ...subPlots];
    },
    characters() {
      return this.$store.getters["characterModule/characterList"];
    },
    manuscripts() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    novelTitle() {
      const { novel } = this;
      return (novel as Novel).title ?? "";
    },
    novelSummary() {
      const { novel } = this;
      return (novel as Novel).description ?? "";
    },
    categoryOptions() {
      const { categories } = this.contest;
      return categories!.map((category) => ({
        id: category.id,
        name: category.name,
      }));
    },
    novelOptions() {
      const { novels } = this;
      return novels.map((novel) => ({
        id: novel.novelId,
        name: novel.title,
        image: this.novelImageKey(novel),
        associatedData: novel.associatedData,
        updatedAtLatestDataInNovel: new Date(novel.updatedAtLatestDataInNovel).getTime(),
      }));
    },
    plotOptions() {
      const { plots } = this;
      if (!plots) return [];
      return plots.map((plot) => {
        if (isSubPlot(plot)) {
          const { plotId, name } = plot;
          return {
            id: plotId,
            name,
          };
        }
        return {
          id: "default",
          name: "全体プロット",
        };
      });
    },
    simpleOptions() {
      return (options) =>
        options.map((option) => ({
          id: option,
          name: option,
        }));
    },
    novelImageKey() {
      return (novel) => {
        const { novelId, image } = novel;
        if (image && image.startsWith("file:")) {
          return `file:novels/${novelId}/cover.jpg`;
        }
        return image;
      };
    },
    inputInitialValue() {
      return (type) => {
        if (type === "title") {
          return this.novelTitle;
        }
        if (type === "synopsis") {
          return this.novelSummary;
        }
        return "";
      };
    },
    selectedCharactersCount() {
      return (entryItemId) => {
        const characters = this.internalValue.entryItems[entryItemId];
        return characters ? characters.length : 0;
      };
    },
    selectedManuscriptsCount() {
      return (entryItemId) => {
        const manuscripts = this.internalValue.entryItems[entryItemId];
        return manuscripts ? manuscripts.length : 0;
      };
    },
    selectedManuscriptsTextCount() {
      return (entryItemId) => {
        const manuscripts = this.internalValue.entryItems[entryItemId];
        const textCount = manuscripts
          ? (manuscripts as Manuscript[]).reduce(
              (sum, manuscript) => sum + (manuscript.content ? manuscript.content.length : 0),
              0
            )
          : 0;
        return textCount.toLocaleString();
      };
    },
    errorMessage() {
      return (type) => {
        const error = this.errors.find((error) => error.path === type);
        return error && error.message;
      };
    },

    canEnterContest() {
      const { contest } = this;
      const { applicationStart, applicationEnd } = contest;
      const start = new Date(applicationStart);
      const end = new Date(applicationEnd);
      const now = new Date();

      return compareAsc(now, start) >= 0 && compareAsc(now, end) <= 0;
    },
    contestStatus() {
      const { contest } = this;
      const { applicationStart, applicationEnd } = contest;
      const start = new Date(applicationStart);
      const end = new Date(applicationEnd);
      const now = new Date();

      if (compareAsc(now, start) < 0) return "応募はまだ始まっていません。";
      if (compareAsc(now, end) > 0) return "応募は終了しました。";
      return "";
    },
    nolaNovelAssociated() {
      const id = this.internalValue?.novel?.associatedData?.nolaNovel?.id;
      if (!id) {
        return null;
      }
      const novels = this.$store.getters["nolaNovelModule/novels"];
      return novels?.find((n) => n.id === id);
    },
  },
  methods: {
    async selectCharacterDialog(entryItemId) {
      const dialog = new Dialog(ContestEntryDataDialog);
      const data: ContestEntryDataDialogProps = {
        type: "characters",
        title: "登場人物の選択（複数可）",
        description: "応募するデータを選択してください。",
        items: this.characters,
        itemsKeyAttribute: "characterId",
        novelId: this.internalValue.novel.id,
        initialSelected: this.internalValue.entryItems[entryItemId],
      };
      const selectedCharacters = await dialog.show(data);
      // NOTE: ここでの代入は参照渡しになるため、Vueのリアクティブに反映される
      this.internalValue.entryItems = { ...this.internalValue.entryItems, [entryItemId]: [...selectedCharacters] };
    },
    async selectManuscriptsDialog(entryItemId) {
      const dialog = new Dialog(ContestEntryDataDialog);
      const data: ContestEntryDataDialogProps = {
        type: "manuscripts",
        title: "原稿の選択（複数可）",
        description: "応募するデータを選択してください。",
        items: this.manuscripts,
        itemsKeyAttribute: "key",
        novelId: this.internalValue.novel.id,
        initialSelected: this.internalValue.entryItems[entryItemId],
      };
      const selectedManuscripts = await dialog.show(data);
      // NOTE: ここでの代入は参照渡しになるため、Vueのリアクティブに反映される
      this.internalValue.entryItems = { ...this.internalValue.entryItems, [entryItemId]: [...selectedManuscripts] };
    },
    openNolaNovelHelpDialog() {
      const completeDialog = new Dialog(SimpleDialog);
      const completeData: SimpleDialogProps = {
        title: "作品の応募条件とは",
        content: `<div class="nola-novel-help-dialog-content">
            <div class="head">公開：</div><div class="body">Nolaノベルへの作品投稿が必要です。\n投稿完了後にご応募お願いします。</div>
            <div class="head">限定公開：</div><div class="body">Nolaノベルへの作品投稿の際に一般公開はせず、\nタグをつけた編集部のみが閲覧可能な設定です。\n設定完了後にご応募お願いします。</div>
          </div>`,
        width: 430,
      };
      completeDialog.show(completeData);
    },
    openNolaNovelPage(path: string) {
      const url = process.env.VUE_APP_NOLANOVEL_WEB + path;
      window.open(url, "_blank");
    },
  },
});

interface Props {
  value: { [key: string]: any };
  contest: NolaContest;
  contestImage?: string;
  isConfirm: boolean;
  errors: ValidationError[];
}

interface Computed {
  internalValue: { [key: string]: any };
  contestEntryItems: ContestEntryItemModel[];
  novels: Novel[];
  novel: Novel | undefined;
  plots: (Plot | SubPlot)[];
  characters: Character[];
  manuscripts: Manuscript[];
  novelTitle: string;
  novelSummary: string;
  categoryOptions: Option[];
  novelOptions: Option[];
  plotOptions: Option[];
  simpleOptions(options: any[]): Option[];
  novelImageKey(nobel: Novel): string | undefined;
  inputInitialValue(type: string): string;
  selectedCharactersCount(entryItemId: string): number;
  selectedManuscriptsCount(entryItemId: string): number;
  selectedManuscriptsTextCount(entryItemId: string): string;
  errorMessage(type: string): string | undefined;
  canEnterContest: boolean;
  contestStatus: string;
  nolaNovelAssociated: { [key: string]: any } | null;
}

interface Methods {
  selectCharacterDialog(entryItemId: string): Promise<void>;
  selectManuscriptsDialog(entryItemId: string): Promise<void>;
  openNolaNovelPage(path: string): void;
  openNolaNovelHelpDialog(): void;
}
