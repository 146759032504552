
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import { showNotifyDialog } from "@/lib/dialog";
import { API, Auth } from "aws-amplify";
import { validate as uuidValidate } from "uuid";
import { NolaApiInitializer } from "@/initializer";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { TextField },
  props: {
    id: String,
  },
  data() {
    return {
      password: "",
      errorMessage: "",
    };
  },
  created() {
    const { id } = this;
    if (!uuidValidate(id)) {
      this.errorMessage = "URLに問題があります。届いたメールを再度確認して下さい。";
    }
  },
  methods: {
    async confirmEmail() {
      const { id, password } = this;
      if (!password) return;

      try {
        NolaApiInitializer.init();

        await API.post("Rest", "/mail/confirmverification", {
          body: {
            id,
            password,
          },
        });

        this.errorMessage = "";
        await showNotifyDialog({ content: "メールアドレスを更新しました。" });

        const result = await Auth.signOut();
        if (!result) {
          localStorage.clear();
        }

        window.location.href = "/auth/signin";
      } catch (err: any) {
        if (err.response.status === 400) {
          this.errorMessage = err.response.data.message;
        } else {
          this.errorMessage = `内部エラーが発生しました ${JSON.stringify(err)}`;
        }
      }
    },
  },
});
