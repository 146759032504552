import { render, staticRenderFns } from "./CarouselBanner.vue?vue&type=template&id=e4806c6c&scoped=true"
import script from "./CarouselBanner.vue?vue&type=script&setup=true&lang=ts"
export * from "./CarouselBanner.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CarouselBanner.vue?vue&type=style&index=0&id=e4806c6c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4806c6c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yamashitatakanori/project/web-studio-yhawk/indent/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e4806c6c')) {
      api.createRecord('e4806c6c', component.options)
    } else {
      api.reload('e4806c6c', component.options)
    }
    module.hot.accept("./CarouselBanner.vue?vue&type=template&id=e4806c6c&scoped=true", function () {
      api.rerender('e4806c6c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/CarouselBanner.vue"
export default component.exports