
import Vue, { PropType } from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";

interface Data {
  isLoading: boolean;
}

interface Props {
  id: string;
  name: string;
  nolaNovelId?: string;
  isPublic?: boolean;
  isLimitedPublic?: boolean;
  isPrivate?: boolean;
  hasEpisodes?: boolean;
  published?: boolean;
  publishCondition?: string[] | null;
}

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onSubmitClick: () => void;
}

interface Computed {
  needsChangePublishStatus: boolean;
  submissionConditionText: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog, MaskedLoading },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    id: String as PropType<string>,
    name: String as PropType<string>,
    nolaNovelId: String as PropType<string>,
    isPublic: Boolean as PropType<boolean | undefined>,
    isLimitedPublic: Boolean as PropType<boolean | undefined>,
    isPrivate: Boolean as PropType<boolean | undefined>,
    hasEpisodes: Boolean as PropType<boolean | undefined>,
    published: Boolean as PropType<boolean | undefined>,
    publishCondition: Array as PropType<string[] | null>,
  },
  computed: {
    needsChangePublishStatus() {
      // publishCondition が空または未設定の場合
      const isPublishConditionEmpty = !this.publishCondition || this.publishCondition.length === 0;

      if (isPublishConditionEmpty) {
        // 条件: publishCondition が空または nullのときは[非公開でない]または[未投稿]の場合
        return !this.isPrivate || !this.published;
      }

      if (this.publishCondition.includes("public") && this.publishCondition.includes("limitedPublic")) {
        // 条件: publishCondition が ["public", "limitedPublic"]のときは[非公開]または[未投稿]の場合
        return this.isPrivate || !this.published;
      }

      if (this.publishCondition.includes("public")) {
        // 条件: publishCondition が ["public"]のときは[限定公開]または[非公開]または[未投稿]の場合
        return this.isLimitedPublic || this.isPrivate || !this.published;
      }

      if (this.publishCondition.includes("limitedPublic")) {
        // 条件: publishCondition が ["limitedPublic"]のときは[公開]または[非公開]または[未投稿]の場合
        return this.isPublic || this.isPrivate || !this.published;
      }

      // デフォルトで false を返す（全ての条件に該当しない場合）
      return false;
    },

    submissionConditionText(): string {
      if (this.publishCondition?.includes("public") && this.publishCondition?.includes("limitedPublic")) {
        // 順不同（publishConditionの配列順は考慮しないAppでは考慮している）
        return '<span class="blue-text">公開中</span>・<span class="blue-text">限定公開</span>';
      }
      if (this.publishCondition?.includes("limitedPublic")) {
        return '<span class="blue-text">限定公開</span>';
      }
      if (this.publishCondition?.includes("public")) {
        return '<span class="blue-text">公開</span>';
      }

      return "<span>指定なし</span>";
    },
  },
  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    async onSubmitClick() {
      this.isLoading = true;
      if (!this.published) {
        console.log("Nolaノベルで投稿する");
        this.$close("/novel/create");
        this.isLoading = false;
        return;
      }

      if (this.needsChangePublishStatus) {
        const isPublic = this.publishCondition?.includes("public") || false;
        const isLimitedPublic = isPublic ? false : this.publishCondition?.includes("limitedPublic") || false;

        await this.$store.dispatch("nolaNovelModule/updateNovel", {
          id: this.nolaNovelId,
          isPublic,
          isLimitedPublic,
        });

        this.$close("reopen");
        this.isLoading = false;
        return;
      }

      if (!this.hasEpisodes) {
        console.log("エピソードを投稿する");
        this.$close("/episode/create");
        this.isLoading = false;
      }
    },
  },
});
