
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    novelTitle: String,
    useWorkCompletedDesign: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    promotionMessage() {
      if (this.useWorkCompletedDesign) {
        return `「${this.novelTitle}」が制作完了になりました！おめでとうございます🐈‍⬛🎉`;
      }
      return `「${this.novelTitle}」の執筆文字数が5万文字を突破しました！`;
    },
  },
  methods: {
    onNavigateToAgent() {
      this.$router.push({ name: "agent" });

      this.$close(true);
    },
    closeDialog() {
      this.$close(true);
    },
  },
});

interface Data {}

interface Methods {
  onNavigateToAgent: () => void;
  closeDialog: () => void;
}

interface Props {
  novelTitle: string;
  useWorkCompletedDesign: boolean;
}

interface Computed {
  promotionMessage: string;
}
