import { render, staticRenderFns } from "./VerticalExportPage.vue?vue&type=template&id=37d10a00&scoped=true"
import script from "./VerticalExportPage.vue?vue&type=script&lang=ts"
export * from "./VerticalExportPage.vue?vue&type=script&lang=ts"
import style0 from "./VerticalExportPage.vue?vue&type=style&index=0&id=37d10a00&lang=scss&scoped=true"
import style1 from "./VerticalExportPage.vue?vue&type=style&index=1&id=37d10a00&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d10a00",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yamashitatakanori/project/web-studio-yhawk/indent/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37d10a00')) {
      api.createRecord('37d10a00', component.options)
    } else {
      api.reload('37d10a00', component.options)
    }
    module.hot.accept("./VerticalExportPage.vue?vue&type=template&id=37d10a00&scoped=true", function () {
      api.rerender('37d10a00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/export/VerticalExportPage.vue"
export default component.exports