
import Vue from "vue";
import SelectBox from "@/components/molecules/SelectBox.vue";
import MultipleSelectBox from "@/components/molecules/MultipleSelectBox.vue";
import CupertinoAlertDialog, { CupertinoAlertDialogProps } from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import { genre as genreList, category as categoryList } from "@/lib/novelSettings";
import { Novel, NovelSetting } from "@/lib/models";
import { VueLoading } from "vue-loading-template";
import { Dialog } from "@/lib/utils";

interface Data {
  title: string;
  genre: NovelSetting | string | null;
  categories: NovelSetting[] | string[] | null;
  wordLength: number | null;
  genreList: NovelSetting[];
  categoryList: NovelSetting[];
  isCreating: boolean;
}

interface Methods {
  onCreateButtonClick: () => void;
  onSelectGenre: (item: NovelSetting) => void;
  onUnSelectGenre: () => void;
  onSelectCategories: (items: NovelSetting[]) => void;

  createNovel: () => void;
  createManuscript: (novelId: String) => void;
}

interface Computed {
  isValidWordLength: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    SelectBox,
    MultipleSelectBox,
    VueLoading,
  },

  data() {
    return {
      title: "",
      genre: null,
      categories: null,
      wordLength: null,
      genreList,
      categoryList,
      isCreating: false,
    };
  },

  methods: {
    onCreateButtonClick() {
      const { createNovel } = this;

      try {
        this.isCreating = true;
        createNovel();
      } catch (e) {
        // 特に何もしない
        console.log(e);
      } finally {
        this.isCreating = false;
      }
    },
    onSelectGenre(item) {
      this.genre = item;
    },
    onUnSelectGenre() {
      this.genre = null;
    },
    onSelectCategories(items) {
      if (items.length > 0) {
        this.categories = items;
        return;
      }

      this.categories = null;
    },

    async createNovel() {
      const { title, wordLength, isValidWordLength } = this;

      if (!title) {
        return;
      }

      if (!isValidWordLength) {
        const completeDialog = new Dialog(CupertinoAlertDialog);
        const data: CupertinoAlertDialogProps = {
          title: "入力エラー",
          content: "執筆予定文字数は半角数字で入力してください。",
          action: "閉じる",
        };
        completeDialog.show(data);
        return;
      }

      let { genre, categories } = this;

      if (genre && typeof genre !== "string") {
        genre = genre.id;
      }

      const isStringArray = (array: NovelSetting[] | string[] | null) => {
        if (!array || array.length === 0) {
          return false;
        }

        return typeof array[0] === "string";
      };

      if (categories && !isStringArray(categories)) {
        categories = (categories as NovelSetting[]).map((category: NovelSetting) => category.id);
      }

      const { dispatch } = this.$store;
      const { novelId } = (await dispatch("novelModule/createNovel", {
        title,
        genre,
        category: categories,
      })) as Novel;

      if (wordLength) {
        const detail = [
          {
            name: "word-length",
            value: wordLength,
          },
        ];
        dispatch("novelThemeModule/updateTheme", { novelId, detail });
      }

      this.createManuscript(novelId);

      this.$ga.event("novel", "create-novel", "執筆をはじめる", 0);
      this.$router.push({ name: "theme", params: { novelId } });
    },
    createManuscript(novelId) {
      this.$store.dispatch("manuscriptModule/createManuscript", {
        novelId,
        title: "",
        content: "",
        manuscriptFolderId: null,
      });
    },
  },

  computed: {
    isValidWordLength() {
      const { wordLength } = this;
      const isNumber = (value: any) => !Number.isNaN(Number(value));
      if (wordLength && !isNumber(wordLength)) {
        return false;
      }

      return true;
    },
  },
});
