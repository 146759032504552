import { defineStore } from "pinia";
import { ref } from "vue";

export const useAgentStore = defineStore("agent", () => {
  const isManuscriptUnsavedAgentNavigation = ref(false);
  const hasDisplayedAgentFeaturePromoBanner = ref(false);

  function setManuscriptUnsavedAgentNavigation(isSaved: boolean) {
    isManuscriptUnsavedAgentNavigation.value = isSaved;
  }

  function setDisplayedAgentFeaturePromoBanner(hasDisplayed: boolean) {
    hasDisplayedAgentFeaturePromoBanner.value = hasDisplayed;
  }

  return {
    isManuscriptUnsavedAgentNavigation,
    setManuscriptUnsavedAgentNavigation,
    hasDisplayedAgentFeaturePromoBanner,
    setDisplayedAgentFeaturePromoBanner,
  };
});

export type AgentStore = ReturnType<typeof useAgentStore>;
