
import Vue from "vue";
import SideMenuLinkSp from "@/components/atoms/SideMenuLinkSP.vue";
import SideMenuLinkList from "@/components/organisms/lists/SideMenuLinkList.vue";
import SideMenuNovel from "@/components/molecules/SideMenuNovel.vue";
import AgentFeaturePromotionBanner from "@/components/molecules/AgentFeaturePromotionBanner.vue";
import AgentFeaturePromotionDialog from "@/components/ui/AgentFeaturePromotionDialog.vue";
import { Route } from "vue-router";
import { Dialog } from "@/lib/utils";
import { useAgentStore, AgentStore } from "@/stores/agent";
import { User } from "@/lib/models";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";

interface Data {
  expand: boolean;
  showMenu: boolean;
  agentStore: AgentStore;
  detail: {
    imageUrl: string;
    text: string;
    promoBannerWordCount: number | null;
  };
  bannerKey: number;
}

interface Computed {
  expandsSideMenu: boolean;
  user: User;
  isAgentFeatureNotAgreed: boolean;
  novelContentLength: number;
  isContentLengthOverThreshold: boolean;
  hasNotUsedAgentFeaturePromoBanner: boolean;
  hasDisplayedAgentFeaturePromoBanner: boolean;
  isManuscriptEmpty: boolean;
  isManuscriptUnsavedAgentNavigation: boolean;
}

interface Methods {
  onClickExpand(): void;
  openAgentFeaturePromotionDialog(): void;
  fetchAgentFeatureDetails(): void;
  evaluateCanDisplayBanner(): void;
  updateCanDisplayBanner(): void;
}

interface Props {
  novelId: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SideMenuLinkList, SideMenuLinkSp, SideMenuNovel, AgentFeaturePromotionBanner },

  async beforeCreate() {
    // プロモーション風バナーの表示制御に利用する原稿保存状態フラグを初期化
    this.$store.commit("manuscriptModule/setManuscriptSaved", true);
    // プロモーション風バナーを作品毎に表示制御するため表示済みフラグを初期化する
    const agentStore = useAgentStore();
    agentStore.setDisplayedAgentFeaturePromoBanner(false);
  },

  async created() {
    // 最新の作品情報をstateに格納する
    const { $store, novelId } = this;
    await $store.dispatch("novelModule/initialize");
    $store.dispatch("novelModule/fetchNovel", { novelId });
    $store.dispatch("novelModule/initializeExpandsSideMenu");

    // プロモーション風バナーのデータをmicroCMSから取得
    this.fetchAgentFeatureDetails();
    // プロモーション風バナーの表示条件を評価
    this.canDisplayBannerResult = await this.evaluateCanDisplayBanner();
  },

  props: {
    novelId: { type: String, required: true },
  },

  data() {
    return {
      expand: true,
      showMenu: true,
      agentStore: useAgentStore(),
      detail: {
        imageUrl: "",
        text: "",
        promoBannerWordCount: null,
      },
      canDisplayBannerResult: false,
      bannerKey: 0,
    };
  },

  watch: {
    $route(newRoute: Route, oldRoute: Route) {
      if (newRoute.name === "editor" && newRoute.params.manuscriptKey != null) {
        this.showMenu = false;
        return;
      }

      this.showMenu = true;
    },
    expandsSideMenu: {
      handler(expandsSideMenu: boolean) {
        this.expand = expandsSideMenu;
      },
      immediate: true,
    },
    novelContentLength() {
      this.updateCanDisplayBanner();
    },
    isManuscriptUnsavedAgentNavigation() {
      this.updateCanDisplayBanner();
    },
  },

  computed: {
    expandsSideMenu() {
      return this.$store.getters["novelModule/expandsSideMenu"];
    },
    user() {
      return this.$store.getters["userModule/user"];
    },
    isAgentFeatureNotAgreed() {
      return this.user.agentStartedAt === null;
    },
    novelContentLength() {
      return this.$store.getters["novelModule/novelContentLength"];
    },
    isContentLengthOverThreshold() {
      return this.novelContentLength >= this.detail.promoBannerWordCount;
    },
    hasNotUsedAgentFeaturePromoBanner() {
      return !this.$store.getters["generalModule/hasUsedAgentFeaturePromoBanner"];
    },
    hasDisplayedAgentFeaturePromoBanner() {
      return this.agentStore.hasDisplayedAgentFeaturePromoBanner;
    },
    isManuscriptEmpty() {
      return this.$store.getters["manuscriptModule/manuscriptList"].length === 0;
    },
    isManuscriptUnsavedAgentNavigation() {
      return this.agentStore.isManuscriptUnsavedAgentNavigation;
    },
  },

  methods: {
    onClickExpand() {
      this.$store.dispatch("novelModule/toggleSideMenu");
    },
    openAgentFeaturePromotionDialog() {
      const agentFeaturePromotionDialog = new Dialog(AgentFeaturePromotionDialog);
      agentFeaturePromotionDialog.show();
    },
    async fetchAgentFeatureDetails() {
      try {
        const axios = axiosBase.create({
          baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
          headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
        });

        const detailRequest = await axios.get(`/agent_feature_detail`);
        if (detailRequest.status !== 200) {
          throw new Error("Failed to fetch data");
        }

        const [content] = detailRequest.data.contents;
        this.detail.imageUrl = content.promoBannerImage.url;
        this.detail.text = content.promoBannerText;
        this.detail.promoBannerWordCount = content.promoBannerWordCount;
      } catch (error) {
        await showNotifyDialog({
          title: "エラー",
          content: "ページの一部の情報の取得に失敗しました。",
        });
        console.error(error);
      }
    },
    async evaluateCanDisplayBanner() {
      // バナー表示判定に利用している、novelContentLengthは他コンポーネントで
      // 更新されるため、Store反映に少し時間がかかる。
      // 反映される前にバナー表示判定を行うと、正しい判定ができないため、0.5秒待機する。
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (this.isManuscriptEmpty) {
        // 原稿が存在しない場合はバナーの表示判定を行わず false を返す
        return false;
      }

      if (this.hasDisplayedAgentFeaturePromoBanner) {
        // 既にバナー表示済みの場合はバナー表示判定を行わず true を返す
        return true;
      }

      // バナーを表示可能な条件を全て満たす場合のみ true を返す
      const canDisplay =
        this.isAgentFeatureNotAgreed && this.isContentLengthOverThreshold && this.hasNotUsedAgentFeaturePromoBanner;

      if (canDisplay) {
        // バナー表示条件を満たす場合はバナー表示済みフラグを立てる
        this.agentStore.setDisplayedAgentFeaturePromoBanner(true);
      }

      return canDisplay;
    },
    async updateCanDisplayBanner() {
      const previousResult = this.canDisplayBannerResult;
      this.canDisplayBannerResult = await this.evaluateCanDisplayBanner();

      // 原稿本文が10万字を超えて保存された場合、バナーの表示判定が再評価されるが、this.canDisplayBannerResult の値に変化がない場合
      // Vue はバナーの再描画を行われないため、バナーの表示判定結果がtrueだった場合、バナーの再描画を行うためにkeyを更新する
      if (previousResult === this.canDisplayBannerResult && this.canDisplayBannerResult) {
        this.bannerKey += 1;
      }
    },
  },
});
