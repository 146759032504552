import { NovelClient } from "@/lib/clients";
import { Novel, NovelSetting, ManuscriptSetting, TCopyNovelPayload } from "@/lib/models";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { workStatus as workStatusList } from "@/lib/novelSettings";
import {
  db,
  ManuscriptSettings as ManuscriptSettingsDexie,
  GeneralSettings as GeneralSettingsDexie,
} from "@/lib/indexeddb";

const novelClient = new NovelClient();

// Stateの型定義
export interface NovelsState {
  novels: Novel[];
  genre: NovelSetting | null;
  category: NovelSetting | null;
  workStatus: NovelSetting;
  sort: NovelSetting | null;
  manuscriptSettings: ManuscriptSetting[];
  expandsSideMenu: boolean;
  novelContentLength: number;
}

type Getters = {
  novel(state: NovelsState): (id: string) => Novel | undefined;
  novels(state: NovelsState): Novel[];
  genre(state: NovelsState): NovelSetting | null;
  category(state: NovelsState): NovelSetting | null;
  workStatus(state: NovelsState): NovelSetting;
  sort(state: NovelsState): NovelSetting | null;
  manuscriptSetting(state: NovelsState): (novelId: string) => ManuscriptSetting;
  expandsSideMenu(state: NovelsState): boolean;
  novelContentLength(state: NovelsState): number;
};

const getters: GetterTree<NovelsState, {}> & Getters = {
  novel: (state) => (id: string) => state.novels.find((novel) => novel.novelId === id),
  novels: (state) => state.novels,
  genre: (state) => state.genre,
  category: (state) => state.category,
  workStatus: (state) => state.workStatus,
  sort: (state) => state.sort,
  manuscriptSetting: (state) => (novelId) => {
    const initialManuscriptSetting: ManuscriptSetting = {
      novelId,
      wordCountOnVertical: 20,
      lineCountPerPage: 20,
      fontSizeRate: 1,
    };
    const manuscriptSetting = state.manuscriptSettings.find(
      (manuscriptSetting) => manuscriptSetting.novelId === novelId
    );
    return manuscriptSetting || initialManuscriptSetting;
  },
  expandsSideMenu: (state) => state.expandsSideMenu,
  novelContentLength: (state) => state.novelContentLength,
};

const mutations: MutationTree<NovelsState> = {
  createNovel(state, payload) {
    return state.novels.push(payload);
  },
  setNovels(state, payload) {
    return (state.novels = payload);
  },
  setGenre(state, payload: NovelSetting | null) {
    return (state.genre = payload);
  },
  setCategory(state, payload: NovelSetting | null) {
    return (state.category = payload);
  },
  setWorkStatus(state, payload: NovelSetting) {
    return (state.workStatus = payload);
  },
  setSort(state, payload: NovelSetting | null) {
    return (state.sort = payload);
  },
  setManuscriptSetting(state, payload: ManuscriptSetting[]) {
    return (state.manuscriptSettings = payload);
  },
  updateManuscriptSetting(state, payload: ManuscriptSetting) {
    const isIncludeManuscriptSetting = state.manuscriptSettings.some(
      (manuscriptSetting) => manuscriptSetting.novelId === payload.novelId
    );
    if (isIncludeManuscriptSetting) {
      state.manuscriptSettings = state.manuscriptSettings.map((manuscriptSetting) => {
        if (manuscriptSetting.novelId === payload.novelId) {
          return payload;
        }
        return manuscriptSetting;
      });
    } else {
      state.manuscriptSettings.push(payload);
    }
    return state.manuscriptSettings;
  },
  setExpandsSideMenu(state, payload: boolean) {
    return (state.expandsSideMenu = payload);
  },
  setNovelContentLength(state, payload: number) {
    return (state.novelContentLength = payload);
  },
};

type FetchNovelPayload = {
  novelId: string;
};

type CreateNovelPayload = {
  title: string;
  genre?: string;
  category?: string[];
};

type UpdateNovelPayload = {
  novelId: string;
  title: string;
  image: string;
  description: string;
  genre?: string;
  category?: string[];
  workStatus?: string;
};

type DeleteNovelPayload = {
  novelId: string;
  title: string;
};

type Actions = {
  /**
   * Fetch
   */
  fetchNovel(this: Store<{}>, injectee: ActionContext<NovelsState, {}>, payload: FetchNovelPayload): Promise<Novel>;

  /**
   * Create
   */

  createNovel(this: Store<{}>, injectee: ActionContext<NovelsState, {}>, payload: CreateNovelPayload): Promise<Novel>;

  /**
   * Update
   */

  updateNovel(this: Store<{}>, injectee: ActionContext<NovelsState, {}>, payload: UpdateNovelPayload): Promise<Novel>;

  /**
   * Delete
   */

  deleteNovel(this: Store<{}>, injectee: ActionContext<NovelsState, {}>, payload: DeleteNovelPayload): Promise<void>;

  /**
   * Copy
   */

  copyNovel(this: Store<{}>, injectee: ActionContext<NovelsState, {}>, payload: TCopyNovelPayload): Promise<boolean>;
};

const actions: ActionTree<NovelsState, {}> & Actions = {
  async initialize({ commit }, _) {
    const novels = await novelClient.fetchAllNovel();
    commit("setNovels", novels);
  },

  selectGenre({ commit }, payload: NovelSetting | null) {
    commit("setGenre", payload);
  },
  selectCategory({ commit }, payload: NovelSetting | null) {
    commit("setCategory", payload);
  },
  selectWorkStatus({ commit }, payload: NovelSetting) {
    commit("setWorkStatus", payload);
  },
  selectSort({ commit }, payload: NovelSetting | null) {
    commit("setSort", payload);
  },

  async fetchNovel({ state, commit }, payload): Promise<Novel> {
    const { novelId } = payload;
    const { novels: preNovels } = state;

    const novel = await novelClient.fetchNovelById(novelId);
    const novels = preNovels.map((preNovel) => {
      if (novel && preNovel.novelId === novel.novelId) {
        return novel;
      }

      return preNovel;
    });

    commit("setNovels", novels);

    return novel;
  },

  async createNovel({ state, commit }, payload): Promise<Novel> {
    const { title, genre, category } = payload;
    const { novels: preNovels } = state;
    const novelId = await novelClient.createNovel(title, genre, category);
    const novel = {
      novelId,
      ...payload,
    };
    const novels = [...preNovels, novel];
    commit("setNovels", novels);

    return { ...novel };
  },

  async updateNovel({ state, commit }, payload): Promise<Novel> {
    const { novels: preNovels } = state;
    const { novelId } = payload;
    let novel = preNovels.find((preNovel) => preNovel.novelId === novelId);

    if (!novel) {
      throw new Error("Novel not found.");
    }

    novel = await novelClient.updateNovel({
      ...novel,
      ...payload,
    });

    const novels = preNovels.map((preNovel) => {
      if (novel && preNovel.novelId === novel.novelId) {
        return novel;
      }

      return preNovel;
    });

    commit("setNovels", novels);

    return novel;
  },

  async updateManuscriptSetting({ commit }, payload: ManuscriptSetting) {
    commit("updateManuscriptSetting", payload);

    const { novelId, wordCountOnVertical, lineCountPerPage, fontSizeRate } = payload;
    await db.transaction("readwrite", db.manuscriptSettings, async () => {
      await db.manuscriptSettings.put(
        new ManuscriptSettingsDexie(novelId, wordCountOnVertical, lineCountPerPage, fontSizeRate)
      );
    });
  },
  async initializeManuscriptSettings({ commit }) {
    const manuscriptSettingsInIndexedDB = await db.transaction(
      "readonly",
      db.manuscriptSettings,
      async () => await db.manuscriptSettings.toArray()
    );
    if (manuscriptSettingsInIndexedDB.length) {
      commit("setManuscriptSetting", manuscriptSettingsInIndexedDB);
    }
  },
  async initializeExpandsSideMenu({ commit }) {
    const expandsSideMenuInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("expandsSideMenu")
    );
    if (expandsSideMenuInIndexedDB) {
      commit("setExpandsSideMenu", expandsSideMenuInIndexedDB.value);
    }
  },
  async toggleSideMenu({ commit, state }) {
    const { expandsSideMenu } = state;

    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(!expandsSideMenu), "expandsSideMenu");
    });

    commit("setExpandsSideMenu", !expandsSideMenu);
  },

  async deleteNovel({ state, commit }, payload): Promise<void> {
    const { novels: preNovels } = state;
    const { novelId, title } = payload;
    await novelClient.deleteNovel(novelId, title);
    const novels = preNovels.filter((preNovel) => preNovel.novelId !== novelId);
    commit("setNovels", novels);
  },

  async copyNovel(_, payload): Promise<boolean> {
    return await novelClient.copyNovel(payload);
  },

  async requestNolaAgentForNovel({ state, commit }, payload): Promise<void> {
    const { novels: preNovels } = state;
    const novel = await novelClient.requestNolaAgentForNovel(payload.novelId, payload.campaignId);
    const novels = preNovels.map((preNovel) => {
      if (novel && preNovel.novelId === novel.novelId) {
        return {
          ...preNovel,
          agentStatus: novel.agentStatus,
          agentStartedAt: novel.agentStartedAt,
          agentCampaignIds: novel.agentCampaignIds,
        };
      }
      return preNovel;
    });
    commit("setNovels", novels);
  },

  async revokeNolaAgentForNovel({ state, commit }, payload): Promise<void> {
    const { novels: preNovels } = state;
    const novel = await novelClient.revokeNolaAgentForNovel(payload.novelId);
    const novels = preNovels.map((preNovel) => {
      if (novel && preNovel.novelId === novel.novelId) {
        return {
          ...preNovel,
          agentStatus: novel.agentStatus,
          agentStartedAt: novel.agentStartedAt,
          agentCampaignIds: novel.agentCampaignIds,
        };
      }
      return preNovel;
    });
    commit("setNovels", novels);
  },
};

export default {
  namespaced: true,
  state: {
    novels: [],
    genre: null,
    category: null,
    workStatus: workStatusList[0],
    sort: null,
    manuscriptSettings: [],
    expandsSideMenu: true,
    novelContentLength: 0,
  },
  getters,
  actions,
  mutations,
};
