
import Vue from "vue";
import SignInConnectedAccountForm from "@/components/organisms/SignInConnectedAccountForm.vue";
import { checkDeprecatedBrowser } from "@/lib/checkDeprecatedBrowser";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo() {
    if (process.env.NODE_ENV === "production") {
      return {};
    }
    return {
      meta: [
        {
          name: "robots",
          content: "none",
        },
      ],
    };
  },
  components: {
    SignInConnectedAccountForm,
  },
  props: {
    email: String,
  },
  created() {
    /** 非推奨ブラウザへの文言表示 */
    checkDeprecatedBrowser();
  },
});
