
import Vue from "vue";
import NovelCreateForm from "@/components/organisms/NovelCreateForm.vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { NovelCreateForm },
  created() {
    window.scrollTo(0, 0);

    // 新規作品作成ページに遷移した際に、既存の原稿リストを削除する
    this.$store.commit("manuscriptModule/setManuscriptList", []);
  },
});
