var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("NolaNovelListItem", {
    attrs: { novel: _vm.novel },
    scopedSlots: _vm._u([
      {
        key: "container",
        fn: function () {
          return [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.novel.title)),
            ]),
            _c("div", { staticClass: "data" }, [
              _c("div", [_vm._v("最終更新日：" + _vm._s(_vm.updatedAt))]),
              _c("div", [_vm._v("作成日：" + _vm._s(_vm.createdAt))]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "right",
        fn: function () {
          return [
            _vm.selectedNovelIds.includes(_vm.novel.novelId)
              ? _c(
                  "button",
                  {
                    staticClass: "button primary",
                    on: { click: _vm.unselectNovel },
                  },
                  [_vm._v(" 選択中 ")]
                )
              : _vm.isAlreadyApplied
              ? _c(
                  "button",
                  { staticClass: "button", attrs: { disabled: "" } },
                  [_vm._v("応募済み")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "button primary-outline",
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.selectNovel },
                  },
                  [_vm._v("選択する")]
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }