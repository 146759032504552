import { ManuscriptClient, NolaNovelClient, NovelClient } from "@/lib/clients";
import {
  AnalyticsDataType,
  AnalyticsFromNolaNovel,
  AnalyticsItem,
  AnalyticsTerm,
  EpisodeFromNolaNovel,
  Manuscript,
  NotificationFromNolaNovel,
  NotificationsFromNolaNovel,
  Novel,
  NovelFromNolaNovel,
  UserFromNolaNovel,
} from "@/lib/models";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const novelClient = new NovelClient();
const manuscriptClient = new ManuscriptClient();
const nolaNovelClient = new NolaNovelClient();

export interface NolaNovelState {
  user: UserFromNolaNovel | null;
  novels: NovelFromNolaNovel[];
  episodes: EpisodeFromNolaNovel[];
  analytics: AnalyticsFromNolaNovel[];
  notifications: {
    unread: NotificationsFromNolaNovel;
    read: NotificationsFromNolaNovel;
  };
}

type Getters = {
  user(state: NolaNovelState): UserFromNolaNovel | null;
  novel(state: NolaNovelState): (id: string) => NovelFromNolaNovel | undefined;
  novels(state: NolaNovelState): NovelFromNolaNovel[];
  episode(state: NolaNovelState): (id: string) => EpisodeFromNolaNovel | undefined;
  episodes(state: NolaNovelState): (novelId?: string) => EpisodeFromNolaNovel[];
  analytics(state: NolaNovelState): AnalyticsFromNolaNovel[];
  analyticsById(state: NolaNovelState): (typeTermDate: string, targetId: string) => AnalyticsItem | undefined;
  notifications(state: NolaNovelState): (isRead: boolean) => NotificationsFromNolaNovel;
};

const getters: GetterTree<NolaNovelState, {}> & Getters = {
  user: (state) => state.user,
  novel: (state) => (id) => state.novels.find((novel) => novel.id === id),
  novels: (state) => state.novels,
  episode: (state) => (id) => state.episodes.find((episode) => episode.id === id),
  episodes: (state) => (novelId) => {
    const { episodes: preEpisodes } = state;
    const episodes = preEpisodes.filter((preEpisode) => preEpisode.type === "BODY");
    if (!novelId) {
      return episodes;
    }

    const filted = episodes.filter((episode) => episode.novelId === novelId);
    const sorted = filted.sort((a, b) => a.order - b.order);

    return sorted;
  },
  analytics: (state) => state.analytics,
  analyticsById: (state) => (typeTermDate, targetId) => {
    let result;
    state.analytics.forEach((x) => {
      x.items.forEach((y) => {
        if (y.typeTermDate === typeTermDate && y.targetId === targetId) result = y;
      });
    });
    return result;
  },
  notifications: (state) => (isRead) => (isRead ? state.notifications.read : state.notifications.unread),
};

type Mutations<S> = {
  setUser(state: S, payload: UserFromNolaNovel): void;
  setNovels(state: S, payload: NovelFromNolaNovel[]): void;
  setEpisodes(state: S, payload: EpisodeFromNolaNovel[]): void;
  setAnalytics(state: S, payload: AnalyticsFromNolaNovel[]): void;
  setNotifications(
    state: S,
    payload: {
      items: NotificationFromNolaNovel[];
      lastKey?: string;
      isRead: boolean;
    }
  ): void;
  updateNovel(state: S, payload: NovelFromNolaNovel): void;
};

const mutations: MutationTree<NolaNovelState> & Mutations<NolaNovelState> = {
  setUser(state, payload) {
    state.user = payload;
  },
  setNovels(state, payload) {
    state.novels = payload;
  },
  setEpisodes(state, payload) {
    state.episodes = payload;
  },
  setAnalytics(state, payload) {
    state.analytics = payload;
  },
  setNotifications(state, payload) {
    const { items, lastKey, isRead } = payload;

    if (isRead) {
      state.notifications.read = { items, lastKey };
    } else {
      state.notifications.unread = { items, lastKey };
    }
  },
  readAllNotifications(state) {
    const newUnread = state.notifications.unread.items.concat(state.notifications.read.items);
    state.notifications.unread.items = [];
    state.notifications.read.items = newUnread.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.isRead = true;
      return item;
    });
  },
  updateNovel(state, payload) {
    state.novels = state.novels.map((novel) => {
      if (novel.id === payload.id) {
        return { ...novel, ...payload };
      }
      return novel;
    });
  },
};

type NovelLinkPayload = {
  novelId: string;
  nolaNovelId: string;
};

type EpisodeLinkPayload = {
  novelId: string;
  manuscriptId: string;
  episode: EpisodeFromNolaNovel;
};

type EpisodeDelinkPayload = {
  novelId: string;
  manuscriptIds: string[];
  episodes: EpisodeFromNolaNovel[] | string[];
};

type AnalyticsPayload = {
  targetId: string;
  type: AnalyticsDataType;
  term?: AnalyticsTerm;
  date?: string;
  start?: string;
  end?: string;
};

type Actions<S, R> = {
  /**
   * Fetch
   */

  fetchUser(this: Store<{}>, injectee: ActionContext<S, R>, paload: unknown): Promise<UserFromNolaNovel>;
  fetchNovel(this: Store<{}>, injectee: ActionContext<S, R>, paload: string): Promise<NovelFromNolaNovel>;
  fetchNovels(this: Store<{}>, injectee: ActionContext<S, R>, paload: unknown): Promise<NovelFromNolaNovel[]>;
  fetchEpisode(this: Store<{}>, injectee: ActionContext<S, R>, paload: string): Promise<EpisodeFromNolaNovel>;
  fetchAnalytics(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    paload: AnalyticsPayload[]
  ): Promise<AnalyticsFromNolaNovel[]>;

  /**
   * Add
   */

  addNovelLink(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: NovelLinkPayload
  ): Promise<NovelFromNolaNovel[]>;
  addEpisodeLink(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: EpisodeLinkPayload
  ): Promise<EpisodeFromNolaNovel[]>;

  /**
   * UpdateList
   */

  updateNovelsState(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: NovelFromNolaNovel
  ): Promise<NovelFromNolaNovel[]>;
  updateEpisodesState(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: EpisodeFromNolaNovel[]
  ): Promise<EpisodeFromNolaNovel[]>;

  /**
   * Delete
   */

  deleteNovelLink(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: NovelLinkPayload
  ): Promise<NovelFromNolaNovel[]>;
  deleteEpisodeLink(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: EpisodeDelinkPayload
  ): Promise<EpisodeFromNolaNovel[]>;

  /**
   * Notification
   */

  fetchNotifications(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    paload: { isRead: boolean }
  ): Promise<NotificationsFromNolaNovel>;
  fetchAllNotifications(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    paload: { isRead: boolean }
  ): Promise<NotificationsFromNolaNovel>;
  loadNotifications(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    paload: { isRead: boolean }
  ): Promise<NotificationsFromNolaNovel>;
  readAllNotifications(this: Store<{}>, injectee: ActionContext<S, R>, paload: unknown): Promise<void>;

  /**
   * Novel
   */

  updateNovel(
    this: Store<{}>,
    injectee: ActionContext<S, R>,
    payload: NovelFromNolaNovel
  ): Promise<{ id: string }>;
};

const actions: ActionTree<NolaNovelState, {}> & Actions<NolaNovelState, {}> = {
  async fetchUser({ commit }, _) {
    const user = await nolaNovelClient.fetchUserFromNolaNovel();

    commit("setUser", user);
    return user;
  },
  async fetchNovel({ state, commit, dispatch }, payload) {
    const novel = await nolaNovelClient.fetchNovelByIdFromNolaNovel(payload);

    if (!novel) {
      throw new Error(`Novel not found. from NolaNovel. novelId: ${payload}`);
    }

    // 既存のStateで持っていれば更新、持っていなければ追加
    const { novels: preNovels } = state;
    const novels = preNovels.filter((preNovels) => preNovels.id !== payload);
    novels.push(novel);

    // Episodeが存在する場合は、状態を更新する
    let episodes: EpisodeFromNolaNovel[] = [];
    const { episodes: fetchEpisodes } = novel;
    if (fetchEpisodes && fetchEpisodes.length > 0) {
      const data = fetchEpisodes.map((episode) => ({
        ...episode,
        novelId: novel.id,
      }));
      episodes = [...episodes, ...data];
    }

    commit("setEpisodes", episodes);
    commit("setNovels", novels);

    return novel;
  },
  async fetchNovels({ commit }, _) {
    const novels = await nolaNovelClient.fetchAllNovelFromNolaNovel();

    if (!novels) {
      throw new Error(`Novel not found. from NolaNovel.`);
    }

    let episodes: EpisodeFromNolaNovel[] = [];
    for (let index = 0; index < novels.length; index += 1) {
      const novel = novels[index];
      const { episodes: fetchEpisodes } = novel;
      if (fetchEpisodes && fetchEpisodes.length > 0) {
        const data = fetchEpisodes.map((episode) => ({
          ...episode,
          novelId: novel.id,
        }));
        episodes = [...episodes, ...data];
      }
    }

    commit("setEpisodes", episodes);
    commit("setNovels", novels);

    return novels;
  },
  async fetchEpisode({ state, commit, dispatch }, payload) {
    let episode = await manuscriptClient.fetchManuscriptByIdFromNolaNovel(payload);

    if (!episode) {
      throw new Error(`Episode not found. from NolaNovel. episodeId: ${payload}`);
    }

    const { novel } = episode;

    if (!novel) {
      throw new Error(`Novel not found. from NolaNovel. episodeId: ${payload}`);
    }

    episode = {
      ...episode,
      novelId: novel.id,
    };

    // 既存のStateで持っていれば更新、持っていなければ追加
    const { episodes: preEpisodes } = state;
    const episodes = preEpisodes.filter((preEpisode) => preEpisode.id !== payload);
    episodes.push(episode);

    // 作品が存在する場合は、状態を更新する
    if (novel) {
      dispatch("updateNovelsState", novel);
    }

    commit("setEpisodes", episodes);

    return episode;
  },
  async fetchAnalytics({ commit }, payload) {
    const result = await nolaNovelClient.fetchAnalytics(payload);

    const resultArray = Object.entries(result).map(([_, value]) => value);

    commit("setAnalytics", resultArray);
    return resultArray;
  },

  async addNovelLink({ state, getters, commit, rootGetters }, payload) {
    // Nola Logic

    const { novelId, nolaNovelId } = payload;
    let preNovels: Novel[] | NovelFromNolaNovel[] = rootGetters["novelModule/novels"] as Novel[];
    let preNovel = rootGetters["novelModule/novel"](novelId);
    let filters: Novel[] | NovelFromNolaNovel[] = preNovels.filter((novel) => novel.novelId !== novelId);
    const { associatedData } = await novelClient.setAssociatedData("NolaNovel", novelId, nolaNovelId);
    let novel = {
      ...preNovel,
      associatedData,
    };
    let novels = [...filters, novel];
    commit("novelModule/setNovels", novels, { root: true });

    // NolaNovel Logic

    const { novelIdsNola } = await nolaNovelClient.addNolaNovelLink(novelId, nolaNovelId);

    preNovels = state.novels;
    preNovel = getters.novel(nolaNovelId);
    filters = preNovels.filter((novel) => novel.id !== nolaNovelId);
    novel = {
      ...preNovel,
      novelIdsNola,
    };
    novels = [...filters, novel];
    commit("setNovels", novels);

    return novels;
  },
  async addEpisodeLink({ state, commit, dispatch, rootGetters }, payload) {
    // Nola logic

    const { novelId, manuscriptId, episode } = payload;
    const preNovel = rootGetters["novelModule/novel"](novelId);
    const { associatedData } = preNovel as Novel;
    // Nolaの作品にNolaノベルの作品IDが紐づいていない場合またはIDが異なる場合は紐付けも行う
    if (!associatedData || associatedData.nolaNovel.id !== episode.novelId) {
      await dispatch("addNovelLink", {
        novelId,
        nolaNovelId: episode.novelId,
      });
    }

    // Nola原稿にNolaノベルのエピソードIDを連携する
    const preManuscripts = rootGetters["manuscriptModule/manuscriptList"] as Manuscript[];
    await manuscriptClient.setAssociatedData("NolaNovel", novelId, [{ manuscriptId, id: episode.id }]);
    const manuscripts = preManuscripts.map((preManuscript) => {
      if (preManuscript.key !== manuscriptId) {
        return preManuscript;
      }

      return {
        ...preManuscript,
        associatedData: {
          nolaNovel: {
            id: episode.id,
          },
        },
      };
    });
    commit("manuscriptModule/setManuscriptList", manuscripts, { root: true });

    // NolaNovel logic

    // NolaノベルのエピソードにNolaの原稿IDを紐づける
    // TODO: APIコールの引数
    const param = {
      linkEpisodeId: manuscriptId,
      linkNovelId: novelId,
      episodeId: episode.id,
    };
    await manuscriptClient.addEpisodeLink([param]);
    const preEpisodes = state.episodes;
    const filters = preEpisodes.filter((preEpisode) => episode.id !== preEpisode.id) as EpisodeFromNolaNovel[];
    const updatedEpisode: EpisodeFromNolaNovel = {
      ...episode,
      novelIdNola: novelId,
      episodeIdNola: manuscriptId,
    };
    const episodes = [...filters, updatedEpisode];
    commit("setEpisodes", episodes);

    return episodes;
  },

  async updateNovelsState({ state, commit }, payload) {
    const { novels: preNovels } = state;
    const novels = preNovels.filter((preNovels) => preNovels.id !== payload.id);
    novels.push(payload);

    commit("setNovels", novels);

    return novels;
  },
  async updateEpisodesState({ state, commit }, payload) {
    const { episodes: preEpisodes } = state;
    const ids = payload.map((episode) => episode.id);

    let episodes = preEpisodes.filter((preEpisode) => ids.includes(preEpisode.id));
    episodes = [...episodes, ...payload];

    commit("setEpisodes", episodes);

    return episodes;
  },

  async deleteNovelLink({ state, getters, dispatch, commit, rootGetters }, payload) {
    const { nolaNovelId } = payload;
    await dispatch("fetchNovel", nolaNovelId);

    // Nola Logic

    const { novelId } = payload;
    let preNovels: Novel[] | NovelFromNolaNovel[] = rootGetters["novelModule/novels"] as Novel[];
    let preNovel = rootGetters["novelModule/novel"](novelId);
    const { associatedData } = await novelClient.setAssociatedData("NolaNovel", novelId, null);
    let novel = {
      ...preNovel,
      associatedData,
    };
    let novels = preNovels.map((preNovel) => {
      if (preNovel.novelId === novelId) {
        return novel;
      }

      return preNovel;
    });
    commit("novelModule/setNovels", novels, { root: true });

    // NolaNovel Logic

    const { novelIdsNola } = await nolaNovelClient.deleteNolaNovelLink(novelId, nolaNovelId);
    preNovels = state.novels;
    preNovel = getters.novel(nolaNovelId);
    novel = {
      ...preNovel,
      novelIdsNola,
    };
    novels = preNovels.map((preNovel) => {
      if (preNovel.id === nolaNovelId) {
        return novel;
      }

      return preNovel;
    });
    commit("setNovels", novels);

    // 紐づく原稿とエピソードの全連携削除
    let manuscripts = rootGetters["manuscriptModule/manuscriptList"] as Manuscript[];
    manuscripts = manuscripts.filter((manuscript) => manuscript.key && manuscript.novelId === novelId);
    const manuscriptIds = manuscripts.map((manuscript) => manuscript.key!);
    if (manuscriptIds.length > 0) {
      const episodes = getters.episodes(nolaNovelId);
      await dispatch("deleteEpisodeLink", { novelId, manuscriptIds, episodes });
    }

    return novels;
  },
  async deleteEpisodeLink({ state, commit, rootGetters }, payload) {
    // Nola Logic

    const { manuscriptIds, novelId } = payload;
    const items = manuscriptIds.map((id) => ({
      manuscriptId: id,
    }));
    await manuscriptClient.setAssociatedData("NolaNovel", novelId, items);
    const preManuscripts = rootGetters["manuscriptModule/manuscriptList"] as Manuscript[];
    const manuscripts = preManuscripts.map((manuscript) => {
      const { key } = manuscript;
      if (!key) {
        return manuscript;
      }

      if (manuscriptIds.includes(key)) {
        return {
          ...manuscript,
          associatedData: {
            nolaNovel: {
              id: null,
            },
          },
        };
      }

      return manuscript;
    });
    commit("manuscriptModule/setManuscriptList", manuscripts, { root: true });

    // NolaNovel Logic

    const { episodes } = payload;
    if (episodes.length < 1) {
      return state.episodes;
    }

    let ids: string[] = [];
    if (typeof episodes[0] === "string") {
      ids = episodes as string[];
    } else {
      ids = (episodes as EpisodeFromNolaNovel[]).map((episode) => episode.id);
    }
    await manuscriptClient.deleteEpisodeLink(ids);
    const preEpisodes = state.episodes;
    const newEpisodes = preEpisodes.map((episode) => {
      if (ids.includes(episode.id)) {
        return {
          ...episode,
          novelIdNola: undefined,
          episodeIdNola: undefined,
        };
      }

      return episode;
    });
    commit("setEpisodes", newEpisodes);

    return newEpisodes;
  },

  async fetchNotifications({ commit }, payload) {
    const { isRead } = payload;

    const result = await nolaNovelClient.fetchNotifications(isRead);

    commit("setNotifications", { ...result, isRead });
    return result;
  },
  async fetchAllNotifications({ commit }, payload) {
    const { isRead } = payload;

    const firstNotifications = await nolaNovelClient.fetchNotifications(isRead, 100);
    let items: NotificationFromNolaNovel[] = [...firstNotifications.items];
    let { lastKey } = firstNotifications;

    while (lastKey) {
      // eslint-disable-next-line no-await-in-loop
      const nextNotifications = await nolaNovelClient.loadNotifications(isRead, lastKey, 100);
      items = [...items, ...nextNotifications.items];
      ({ lastKey } = nextNotifications);
    }

    commit("setNotifications", { items, lastKey, isRead });

    return { items, lastKey };
  },
  async loadNotifications({ state, commit }, payload) {
    const { isRead } = payload;
    const { lastKey } = isRead ? state.notifications.read : state.notifications.unread;

    const result = await nolaNovelClient.loadNotifications(isRead, lastKey!);

    let items: NotificationFromNolaNovel[] = [];
    if (isRead) {
      items = state.notifications.read.items.concat(result.items);
    } else {
      items = state.notifications.unread.items.concat(result.items);
    }

    commit("setNotifications", { items, lastKey: result.lastKey, isRead });

    return result;
  },
  async readAllNotifications({ state, commit }, _) {
    const typeTargetIdSubTargetIds = state.notifications.unread.items.map((item) => item.typeTargetIdSubTargetId);
    await nolaNovelClient.readNotification(typeTargetIdSubTargetIds);

    commit("readAllNotifications");
  },
  async updateNovel({ commit }, payload) {
    const result = await nolaNovelClient.updateNovel(payload);

    commit("updateNovel", result);
    return result;
  }
};

const state: NolaNovelState = {
  user: null,
  novels: [],
  episodes: [],
  analytics: [],
  notifications: {
    unread: {
      items: [],
    },
    read: {
      items: [],
    },
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
