import { render, staticRenderFns } from "./Terms.vue?vue&type=template&id=8eb5576e"
import script from "./Terms.vue?vue&type=script&lang=ts"
export * from "./Terms.vue?vue&type=script&lang=ts"
import style0 from "./Terms.vue?vue&type=style&index=0&id=8eb5576e&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yamashitatakanori/project/web-studio-yhawk/indent/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8eb5576e')) {
      api.createRecord('8eb5576e', component.options)
    } else {
      api.reload('8eb5576e', component.options)
    }
    module.hot.accept("./Terms.vue?vue&type=template&id=8eb5576e", function () {
      api.rerender('8eb5576e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/statics/Terms.vue"
export default component.exports