var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "root" }, [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "vertical-export" }, [
          _c("div", { staticClass: "contents" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }