var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "ContestEntryItem",
        {
          staticClass: "contest-entry-item",
          attrs: { name: "選択中のイベント" },
        },
        [
          _c("div", { staticClass: "contest-data" }, [
            _c("img", {
              staticClass: "contest-image",
              attrs: { src: _vm.contestImage, alt: "contest image" },
            }),
            _c("div", { staticClass: "contest-name" }, [
              _vm._v(_vm._s(_vm.contest.name)),
            ]),
          ]),
        ]
      ),
      _vm.canEnterContest
        ? [
            _vm.contest.categories
              ? [
                  _c(
                    "ContestEntryItem",
                    {
                      staticClass: "contest-entry-item",
                      attrs: {
                        name: "応募部門",
                        required: _vm.isConfirm ? undefined : true,
                        error: _vm.errorMessage("category"),
                      },
                    },
                    [
                      _c("SelectBoxV2", {
                        attrs: {
                          options: _vm.categoryOptions,
                          placeholder: "部門を選択してください",
                          readonly: _vm.isConfirm,
                          error: !!_vm.errorMessage("category"),
                        },
                        model: {
                          value: _vm.internalValue.category,
                          callback: function ($$v) {
                            _vm.$set(_vm.internalValue, "category", $$v)
                          },
                          expression: "internalValue.category",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            !_vm.contest.categories || _vm.internalValue.category
              ? _c(
                  "ContestEntryItem",
                  {
                    staticClass: "contest-entry-item",
                    attrs: {
                      name: "提出作品",
                      description: _vm.isConfirm
                        ? undefined
                        : _vm.contest.platform === "nola"
                        ? "Nola内に保存されている作品データから、提出する作品を選択できます。"
                        : "NolaからNolaノベルに投稿連携している作品から、提出作品を選択してください。",
                      required: _vm.isConfirm ? undefined : true,
                      error: _vm.errorMessage("novel"),
                    },
                  },
                  [
                    _vm.contest.platform === "nola"
                      ? _c("SelectBoxV2", {
                          attrs: {
                            options: _vm.novelOptions,
                            placeholder: "作品を選択してください",
                            placeholderImage: "/img/placeholders/novel.png",
                            readonly: _vm.isConfirm,
                            error: !!_vm.errorMessage("category"),
                          },
                          model: {
                            value: _vm.internalValue.novel,
                            callback: function ($$v) {
                              _vm.$set(_vm.internalValue, "novel", $$v)
                            },
                            expression: "internalValue.novel",
                          },
                        })
                      : [
                          _c(
                            "div",
                            { staticClass: "contest-publish-condition" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "help-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openNolaNovelHelpDialog()
                                    },
                                  },
                                },
                                [_vm._v("？")]
                              ),
                              _c("div", [_vm._v("作品の応募条件：")]),
                              _vm._l(
                                _vm.contest.publishCondition,
                                function (condition, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "publish-condition",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            condition === "public"
                                              ? "公開"
                                              : condition === "limitedPublic"
                                              ? "限定公開"
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c("ContestNolaNovelSelectItem", {
                            attrs: {
                              options: _vm.novelOptions,
                              publishCondition: _vm.contest.publishCondition,
                              placeholder: "作品を選択してください",
                              placeholderImage: "/img/placeholders/novel.png",
                              readonly: _vm.isConfirm,
                              error: !!_vm.errorMessage("category"),
                            },
                            model: {
                              value: _vm.internalValue.novel,
                              callback: function ($$v) {
                                _vm.$set(_vm.internalValue, "novel", $$v)
                              },
                              expression: "internalValue.novel",
                            },
                          }),
                          _vm.nolaNovelAssociated
                            ? _c(
                                "div",
                                {
                                  staticClass: "link",
                                  attrs: { target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openNolaNovelPage(
                                        `/novel/${_vm.nolaNovelAssociated?.id}`
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " 提出作品の確認：" +
                                      _vm._s(_vm.nolaNovelAssociated?.title) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _vm.internalValue.novel
              ? [
                  _vm._l(_vm.contestEntryItems, function (entryItem) {
                    return [
                      _c(
                        "ContestEntryItem",
                        {
                          key: `${entryItem.id}_${_vm.internalValue.novel.id}`,
                          staticClass: "contest-entry-item",
                          attrs: {
                            name: entryItem.name,
                            description: _vm.isConfirm
                              ? undefined
                              : entryItem.description,
                            required: _vm.isConfirm
                              ? undefined
                              : !entryItem.optional,
                            error: _vm.errorMessage(
                              `entryItems.${entryItem.id}`
                            ),
                          },
                        },
                        [
                          entryItem.type === "string" ||
                          entryItem.type === "number" ||
                          entryItem.type === "title" ||
                          entryItem.type === "synopsis"
                            ? [
                                _c("InputTextV2", {
                                  attrs: {
                                    initialValue: _vm.inputInitialValue(
                                      entryItem.type
                                    ),
                                    type:
                                      entryItem.type === "number"
                                        ? "number"
                                        : "text",
                                    placeholder: `${entryItem.name}を入力してください`,
                                    min:
                                      entryItem.type === "number"
                                        ? entryItem.min
                                        : undefined,
                                    max:
                                      entryItem.type === "number"
                                        ? entryItem.max
                                        : undefined,
                                    minlength:
                                      entryItem.type !== "number"
                                        ? entryItem.min
                                        : undefined,
                                    maxlength:
                                      entryItem.type !== "number"
                                        ? entryItem.max
                                        : undefined,
                                    readonly:
                                      entryItem.type === "title" ||
                                      _vm.isConfirm,
                                    rows: entryItem.multiLine ? 5 : undefined,
                                    isDisplayCount:
                                      entryItem.max !== undefined &&
                                      !_vm.isConfirm,
                                    error: !!_vm.errorMessage(
                                      `entryItems.${entryItem.id}`
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.internalValue.entryItems[
                                        entryItem.id
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.internalValue.entryItems,
                                        entryItem.id,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "internalValue.entryItems[entryItem.id]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          entryItem.type === "select" ||
                          entryItem.type === "plot"
                            ? [
                                _c("SelectBoxV2", {
                                  attrs: {
                                    options:
                                      entryItem.type === "plot"
                                        ? _vm.plotOptions
                                        : _vm.simpleOptions(entryItem.options),
                                    placeholder: `${entryItem.name}を選択してください`,
                                    readonly: _vm.isConfirm,
                                    error: !!_vm.errorMessage(
                                      `entryItems.${entryItem.id}`
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.internalValue.entryItems[
                                        entryItem.id
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.internalValue.entryItems,
                                        entryItem.id,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "internalValue.entryItems[entryItem.id]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          entryItem.type === "characters"
                            ? [
                                !_vm.isConfirm
                                  ? _c("SelectBoxV2", {
                                      staticClass: "margin-bottom-10",
                                      attrs: {
                                        onClickHandler: () =>
                                          _vm.selectCharacterDialog(
                                            entryItem.id
                                          ),
                                        text: `提出作品内のデータ（${_vm.selectedCharactersCount(
                                          entryItem.id
                                        )}件）を提出`,
                                        error: !!_vm.errorMessage(
                                          `entryItems.${entryItem.id}`
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm.internalValue.entryItems[entryItem.id] &&
                                _vm.internalValue.entryItems[entryItem.id]
                                  .length
                                  ? [
                                      _c("div", { staticClass: "list-title" }, [
                                        _vm._v("▼ 選択中の登場人物"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "list" },
                                        [
                                          _vm._l(
                                            _vm.internalValue.entryItems[
                                              entryItem.id
                                            ],
                                            function (character) {
                                              return [
                                                _c("CharacterItem", {
                                                  key: character.characterId,
                                                  staticClass: "item",
                                                  attrs: {
                                                    novelId:
                                                      _vm.internalValue.novel
                                                        .id,
                                                    character: character,
                                                  },
                                                }),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          entryItem.type === "manuscripts"
                            ? [
                                !_vm.isConfirm
                                  ? _c("SelectBoxV2", {
                                      staticClass: "margin-bottom-10",
                                      attrs: {
                                        onClickHandler: () =>
                                          _vm.selectManuscriptsDialog(
                                            entryItem.id
                                          ),
                                        text: `提出作品内のデータ（${_vm.selectedManuscriptsCount(
                                          entryItem.id
                                        )}件）を提出`,
                                        error: !!_vm.errorMessage(
                                          `entryItems.${entryItem.id}`
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm.internalValue.entryItems[entryItem.id] &&
                                _vm.internalValue.entryItems[entryItem.id]
                                  .length
                                  ? [
                                      _c("div", { staticClass: "list-title" }, [
                                        _vm._v("▼ 選択中の原稿タイトル"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "list" },
                                        [
                                          _vm._l(
                                            _vm.internalValue.entryItems[
                                              entryItem.id
                                            ],
                                            function (manuscript) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    key: manuscript.key,
                                                    staticClass: "item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          manuscript.title ||
                                                            "（名称未設定）"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      !_vm.isConfirm
                                        ? _c("div", { staticClass: "count" }, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  `合計 ${_vm.selectedManuscriptsTextCount(
                                                    entryItem.id
                                                  )} 字`
                                                )
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          entryItem.type === "nolaNovelCheckboxGroups"
                            ? [
                                _c("NolaNovelCheckboxGroups", {
                                  attrs: {
                                    nolaNovelItem: _vm.nolaNovelAssociated,
                                    settings: entryItem.checkboxGroupsSettings,
                                    readonly: _vm.isConfirm,
                                    error: !!_vm.errorMessage(
                                      "nolaNovelCheckboxGroups"
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.internalValue.entryItems[
                                        entryItem.id
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.internalValue.entryItems,
                                        entryItem.id,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "internalValue.entryItems[entryItem.id]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  }),
                ]
              : _vm._e(),
          ]
        : [
            _c("div", { staticClass: "contest-status" }, [
              _vm._v(_vm._s(_vm.contestStatus)),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }