
import Vue from "vue";
import { Auth } from "aws-amplify";
import TextField from "@/components/molecules/TextField.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";

interface Data {
  username: string;
  message: string;
  submitting: boolean;
}

interface Methods {
  submit(): Promise<void>;
}

interface Computed {
  from: string | undefined;
}

export default Vue.extend<Data, Methods, Computed>({
  // NOTE: metaタグの設定
  metaInfo() {
    if (process.env.NODE_ENV === "production") {
      return {};
    }
    return {
      meta: [
        {
          name: "robots",
          content: "none",
        },
      ],
    };
  },
  components: { TextField, ButtonDefault },
  data() {
    return {
      username: "",
      message: "",
      submitting: false,
    };
  },
  computed: {
    from() {
      return this.$route.query.from;
    },
  },
  created() {
    if (this.from === "nolanovel") {
      document.title = "Nolaノベル パスワード再設定";
    } else if (this.from === "default") {
      document.title = "Nola関連アカウント パスワード再設定";
    }
  },
  methods: {
    async submit() {
      const { username } = this;

      try {
        this.submitting = true;

        if (this.from === "nolanovel") {
          // Nolaノベルのユーザーの場合はclientMetadataに「serviceName: Nolaノベル」を追加
          // そうすることで、本人認証のための確認コード送信メールにて、「Nolaノベル」から送信されたことになる
          await Auth.forgotPassword(username, { serviceName: "Nolaノベル" });
        } else if (this.from === "default") {
          await Auth.forgotPassword(username, { serviceName: "Nola関連サービス" });
        } else {
          await Auth.forgotPassword(username);
        }

        // クエリパラメータを動的に構築する
        const query = this.from ? { from: this.from } : {};

        // ルートを遷移する
        this.$router.push({
          name: "resetPasswordSubmit",
          params: { username },
          query,
        });
      } catch (error) {
        this.submitting = false;
        console.error(error);
        if (error.code === "UserNotFoundException") {
          this.message = "このメールアドレスの登録ユーザーが見つかりません。今一度ご確認の上、ご入力ください。";
        } else if (error.code === "LimitExceededException") {
          this.message = "既定のリセット回数を超えました。しばらく待ってから再実施してください。";
        } else {
          this.message = "エラーが発生しました。";
        }
      }
    },
  },
});
