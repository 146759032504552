var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.Carousel,
        {
          attrs: {
            "per-page": 1,
            autoplay: true,
            loop: true,
            autoplayHoverPause: true,
            autoplayTimeout: 4000,
            paginationPadding: 3,
            paginationActiveColor: `#7F7F7F`,
            paginationColor: `#D0D0D0`,
          },
        },
        _vm._l(_setup.banners, function (item, index) {
          return _c(_setup.Slide, { key: index }, [
            item.id.includes("banner-commerce-debut")
              ? _c(
                  "div",
                  {
                    staticClass: "clickable-banner",
                    on: {
                      click: function ($event) {
                        return _setup.handleCommerceBannerClick()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "banner-img",
                      attrs: { src: `${item.image.url}?w=600` },
                    }),
                  ]
                )
              : _c("a", { attrs: { href: item.link, target: item.link } }, [
                  _c("img", {
                    staticClass: "banner-img",
                    attrs: { src: `${item.image.url}?w=600` },
                  }),
                ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }