
import { User } from "@/lib/models";
import Vue from "vue";
import CarouselBanner from "@/components/organisms/CarouselBanner.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CarouselBanner,
  },
  computed: {
    isActivePath() {
      return (name) => {
        const { path } = this.$route;
        return path === name || path === `${name}/`;
      };
    },
    user() {
      return this.$store.getters["userModule/user"];
    },
    unreadItemCount() {
      return this.$store.getters["notificationModule/notificationSummary"]?.unreadItemCount;
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  isActivePath(name: string): boolean;
  user: User;
  unreadItemCount: number;
}

interface Methods {}
