
import Vue, { PropType } from "vue";
import SelectBoxItem from "@/components/atoms/SelectBoxItem.vue";
import ChevronDownIcon from "icons/ChevronDown.vue";
import ImageView from "@/components/atoms/ImageView.vue";
import { Dialog } from "@/lib/utils/dialog";
import NolaNovelContestConfirmDialog from "@/components/ui/dialogs/NolaNovelContestConfirmDialog.vue";
import { Auth } from "aws-amplify";
import { NovelFromNolaNovel } from "@/lib/models/nolaNovel";

const ClickOutside = require("vue-click-outside");

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: { SelectBoxItem, ChevronDownIcon, ImageView },
  model: {
    prop: "selected",
    event: "select",
  },
  props: {
    selected: Object as PropType<Option>,
    initialSelected: Object as PropType<object>,
    options: Array as PropType<Option[]>,
    placeholder: String as PropType<string>,
    placeholderImage: String as PropType<string>,
    onClickHandler: Function as PropType<() => void>,
    publishCondition: Array as PropType<string[] | null>,
    text: String as PropType<string>,
    allowUnselected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  async created() {
    if (this.initialSelected) {
      this.$emit("select", this.initialSelected);
    }

    await this.$store.dispatch("novelModule/initialize");
    await this.$store.dispatch("nolaNovelModule/fetchNovels");
  },
  methods: {
    async onClickSelectBox() {
      if (this.readonly) return;

      if (this.onClickHandler) {
        this.onClickHandler();
      } else {
        this.isOpen = !this.isOpen;
      }

      if (this.isOpen) {
        await this.$store.dispatch("novelModule/initialize");
        await this.$store.dispatch("nolaNovelModule/fetchNovels");
      }
    },
    onClickItem(item) {
      this.$emit("select", item);
    },
    onClickOutside() {
      this.isOpen = false;
    },
    async onClickDisabledItem(item) {
      if (!item) return;

      const dialog = new Dialog(NolaNovelContestConfirmDialog);
      const data = {
        ...item,
        nolaNovelId: item.associatedData?.nolaNovel.id as string | undefined,
        publishCondition: this.publishCondition,
      };

      const result = await dialog.show(data);
      if (!result) {
        return;
      }

      if (result === "reopen") {
        this.isOpen = true;
        return;
      }

      const nolaNovelId = this.nolaNovelRelatedNovels[item.associatedData?.nolaNovel.id]?.id;
      const params = new URLSearchParams();

      let url: string;

      if (!nolaNovelId && result === "/novel/create") {
        url = `${process.env.VUE_APP_NOLANOVEL_WEB}${result}`;
        params.append("novelid", item.id as string);
      } else if (nolaNovelId && result === "/episode/create") {
        url = `${process.env.VUE_APP_NOLANOVEL_WEB}${result}/${nolaNovelId}`;
      } else {
        url = `${process.env.VUE_APP_NOLANOVEL_WEB}/mypage/novel/${nolaNovelId}`;
      }

      const user = await Auth.currentUserPoolUser();
      const { idToken } = user.signInUserSession;
      params.append("token", idToken.jwtToken);
      params.append("userId", user.attributes.sub);
      window.open(`${url}?${params.toString()}`, "_blank");
    },
  },
  computed: {
    nolaNovelRelatedNovels() {
      const novels: NovelFromNolaNovel[] = this.$store.getters["nolaNovelModule/novels"];
      const items = {} as { [novelId: string]: any };
      novels.forEach((novel) => {
        if (this.options.find((option) => option.associatedData?.nolaNovel.id === novel.id)) {
          items[novel.id] = novel;
        }
      });
      return items;
    },
    categorized() {
      const novels = this.nolaNovelRelatedNovels;

      const res = {
        nolaNovelAssociated: [] as NovelCategorized[],
        other: [] as NovelCategorized[],
      };

      this.options.forEach((option: Option) => {
        if (option.associatedData?.nolaNovel) {
          const nolaNovelData = novels[option.associatedData.nolaNovel.id];
          const optionData = {
            ...option,
            isPublic: nolaNovelData?.isPublic,
            isLimitedPublic: nolaNovelData?.isLimitedPublic,
            isPrivate: !nolaNovelData?.isPublic && !nolaNovelData?.isLimitedPublic,
            hasEpisodes: nolaNovelData?.episodes.length > 0,
            published: Boolean(nolaNovelData),
            updatedAt: nolaNovelData?.updatedAt,
          };

          if (!nolaNovelData) {
            res.other.push(optionData);
          } else if (!this.publishCondition || this.publishCondition.length === 0) {
            res.other.push(optionData);
          } else if (!optionData.hasEpisodes) {
            res.other.push(optionData);
          } else if (this.publishCondition.includes("limitedPublic") && nolaNovelData.isLimitedPublic) {
            res.nolaNovelAssociated.push({ ...optionData });
          } else if (this.publishCondition.includes("public") && nolaNovelData.isPublic) {
            res.nolaNovelAssociated.push({ ...optionData });
          } else {
            res.other.push(optionData);
          }
        } else {
          res.other.push(option);
        }
      });

      /** Booleanの並び順を -1, 0, 1 で返す */
      function sortBoolean(a: boolean | undefined, b: boolean | undefined) {
        if (a && !b) return -1;
        if (!a && b) return 1;
        return 0;
      }

      function sortFunc(a: NovelCategorized, b: NovelCategorized) {
        // 並び順を、公開中＞限定公開＞非公開＞未投稿かつ更新日の降順にする
        return sortBoolean(a.isPublic, b.isPublic) ||
          sortBoolean(a.isLimitedPublic, b.isLimitedPublic) ||
          sortBoolean(a.isPrivate, b.isPrivate) ||
          sortBoolean(a.published, b.published) ||
          (b.updatedAt || 0) - (a.updatedAt || 0) ||
          (b.updatedAtLatestDataInNovel || 0) - (a.updatedAtLatestDataInNovel || 0);
      }
      res.other.sort(sortFunc);
      res.nolaNovelAssociated.sort(sortFunc);
      return res;
    },
    nolaNovelTitle() {
      if (!this.selected) return null;

      const nolaNovelId = this.selected.associatedData?.nolaNovel?.id;
      if (!nolaNovelId) return null;

      const novelFromNolaNovel = this.nolaNovelRelatedNovels[nolaNovelId];
      if (!novelFromNolaNovel) return null;

      return `Nolaノベル作品名：${novelFromNolaNovel.title}`;
    },
  },
});

interface Props {
  selected: Option;
  initialSelected?: object;
  options: Option[];
  placeholder: string;
  placeholderImage?: string;
  onClickHandler?: () => void;
  text?: string;
  publishCondition?: string[] | null;
  allowUnselected: boolean;
  readonly: boolean;
  error: boolean;
}

interface Data {
  isOpen: boolean;
}

interface Computed {
  categorized: {
    /** 連携中 */
    nolaNovelAssociated: NovelCategorized[];
    /** その他 */
    other: NovelCategorized[];
  };
  nolaNovelRelatedNovels: { [novelId: string]: any };
  nolaNovelTitle: string | null;
}

interface NovelCategorized extends Option {
  isPublic?: boolean;
  isLimitedPublic?: boolean;
  isPrivate?: boolean;
  hasEpisodes?: boolean;
  published?: boolean;
  updatedAt?: number;
}

interface Methods {
  onClickSelectBox(): void;
  onClickItem(item?: Option): void;
  onClickDisabledItem(item?: NovelCategorized): void;
  onClickOutside(): void;
}

export type Option = {
  id: string | number;
  name: string;
  image?: string;
  associatedData?: any;
  updatedAtLatestDataInNovel?: number;
};
