var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "error-page" } }, [
    _c("header", { attrs: { id: "header" } }, [
      _c(
        "span",
        { staticClass: "flex-item-logo" },
        [
          _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "logo-img",
              attrs: { src: require("@/assets/img/logo.png") },
            }),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm._m(0),
          _vm._m(1),
          _c("router-link", { attrs: { to: "/" } }, [
            _c("button", [_vm._v("トップページに戻る")]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("h2", [_vm._v("お探しのページが見つかりませんでした")]),
      _c("span", [_vm._v("Page not Found")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/static/sorry.png"), alt: "sorry" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }