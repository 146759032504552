// Data Model

export interface UserFromNolaNovel {
  id: string;
  name: string;
  profile: string;
  imagePath: string;
  usePurpose: string;
  followCount: number;
  followerCount: number;
  createdAt: number;
  updatedAt: number;
  novels: NovelFromNolaNovel[];
}

export interface NovelFromNolaNovel {
  id: string;
  title: string;
  imagePath: string;
  createdAt: number;
  updatedAt: number;
  novelIdsNola: string[];
  bookmarkCount: number;
  genre: GenreFromNolaNovel;
  subGenre: SubGenreFromNolaNovel;
  tag: string[];
  isPublic: boolean;
  isLimitedPublic?: boolean;
  episodes?: EpisodeFromNolaNovel[];
}

export interface EpisodeFromNolaNovel {
  id: string;
  novelId: string;
  title: string;
  type: string;
  order: number;
  createdAt: number;
  updatedAt: number;
  publishedAt: number;
  characterCount: number;
  novelIdNola?: string;
  episodeIdNola?: string;

  novel?: NovelFromNolaNovel;
}

export interface AnalyticsFromNolaNovel {
  count: number;
  items: AnalyticsItem[];
}

export interface AnalyticsItem {
  targetId: string;
  typeTermDate: string;
  count: number;
  meta?: string;
  createdAt: number;
  updatedAt: number;
}

export interface ListAnalytics {
  [key: string]: AnalyticsFromNolaNovel;
}

export interface NotificationsFromNolaNovel {
  items: NotificationFromNolaNovel[];
  lastKey?: string;
}

export type NotificationFromNolaNovel = {
  novel?: {
    id: string;
    title: string;
  };
  episode?: {
    id: string;
    title: string;
  };
  comment?: {
    id: string;
    body: string;
  };
  user?: {
    id: string;
    name: string;
  };
  activity?: {
    id: string;
    title: string;
  };
  company?: {
    id: string;
    name: string;
  };
  userId: string;
  typeTargetIdSubTargetId: string;
  type: string;
  isRead: boolean;
  publishedAt: number;
};

// Input Model

export interface AnalyticsFromNolaNovelInput {
  targetId: string;
  type: AnalyticsDataType;
  term?: AnalyticsTerm;
  date?: string;
  start?: string;
  end?: string;
}

// Enum

export enum AnalyticsDataType {
  /**
   * 読了数
   */
  READ = "READ",
  /**
   * ページビュー数
   */
  PV = "PV",
  /**
   * ターゲットに関連する作品詳細画面PV数
   */
  PV_NOVEL = "PV_NOVEL",
  /**
   * ターゲットに関連する全エピソードPV数
   */
  PV_EPISODE = "PV_EPISODE",
  /**
   * 流入元がDIRECTのPV数
   */
  PV_DIRECT = "PV_DIRECT",
  /**
   * IMP数
   */
  IMP = "IMP",
  /**
   * ターゲットに関連する作品IMP数
   */
  IMP_NOVEL = "IMP_NOVEL",
  /**
   * みんなの評価数
   */
  REVIEW = "REVIEW",
  /**
   * ブックマーク数
   */
  BOOKMARK = "BOOKMARK",
  /**
   * コメント数
   */
  COMMENT = "COMMENT",
  /**
   * スタンプ数
   */
  STAMP = "STAMP",
}

export enum AnalyticsTerm {
  ALL = "ALL", // 全期間
  DAILY = "DAILY", // 日間のデータ
  MONTHLY = "MONTHLY", // 月間のデータ
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum NotificationSortType {
  PUBLISHED_AT = "PUBLISHED_AT",
}

export enum NotificationDataType {
  NOVEL_UPDATED = "NOVEL_UPDATED", // ブックマークした書籍が更新されました！
  FOLLOW_NEWNOVEL = "FOLLOW_NEWNOVEL", // フォローした作家さんの新作小説が投稿されました！
  FOLLOW_NEWEPISODE = "FOLLOW_NEWEPISODE", // フォローした作家さんの新作エピソードが投稿されました！
  FOLLOW_NEWACTIVITY = "FOLLOW_NEWACTIVITY",
  BOOKMARK = "BOOKMARK", // ブックマークされました！
  FOLLOW = "FOLLOW", // フォローされました！
  REVIEW = "REVIEW", // 作品に感想が届きました！
  COMMENT = "COMMENT", // コメントが届きました
  COMMENT_REPLY = "COMMENT_REPLY", // あなたのコメントに返信が届きました
  COMPANY_FOOTPRINTS = "COMPANY_FOOTPRINTS", // 編集部からあしあとがつきました！
  COMPANY_FOOTPRINTS_EPISODE = "COMPANY_FOOTPRINTS_EPISODE", // 編集部からエピソードのあしあとがつきました！
  COMPANY_BOOKMARK = "COMPANY_BOOKMARK", // 編集部の注目フラグが立ちました！
  COMPANY_FEATURED_NOVEL = "COMPANY_FEATURED_NOVEL", // 編集部の注目作品に掲載されました！
  COMMENT_ACTIVITY = "COMMENT_ACTIVITY",
  COMMENT_ACTIVITY_REPLY = "COMMENT_ACTIVITY_REPLY", // あなたの活動報告のコメントに返信が届きました
  FAVORITE_ACTIVITY = "FAVORITE_ACTIVITY",
}
export type NotificationDataTypes = typeof NotificationDataType[keyof typeof NotificationDataType];

enum GenreFromNolaNovel {
  /** 恋愛 */
  LOVE = "LOVE",
  /** ファンタジー */
  FANTASY = "FANTASY",
  /** 異世界ファンタジー */
  ISEKAI = "ISEKAI",
  /** 現代・青春ドラマ */
  DRAMA = "DRAMA",
  /** バトル・アクション */
  ACTION = "ACTION",
  /** SF */
  SF = "SF",
  /** ミステリー */
  MYSTERY = "MYSTERY",
  /** ホラー */
  HORROW = "HORROW",
  /** 歴史・時代 */
  HISTORY = "HISTORY",
  /** ギャグ・コメディー */
  COMEDY = "COMEDY",
  /** 児童書・童話 */
  CHILDREN = "CHILDREN",
  /** 詩・短歌 */
  POEM = "POEM",
  /** ノンジャンル */
  NONGENRE = "NONGENRE",
  /** その他 */
  OTHER = "OTHER",
}

enum SubGenreFromNolaNovel {
  /** 一般 */
  LOVE_GENERAL = "LOVE_GENERAL",
  /** 純愛 */
  LOVE_PURE = "LOVE_PURE",
  /** 大人の恋愛 */
  LOVE_ADULT = "LOVE_ADULT",
  /** 泥沼系 */
  LOVE_MUD = "LOVE_MUD",
  /** 禁断の関係 */
  LOVE_FORBIDDEN = "LOVE_FORBIDDEN",
  /** 歪んだ愛 */
  LOVE_DISTORTED = "LOVE_DISTORTED",
  /** 感動系 */
  LOVE_IMPRESSION = "LOVE_IMPRESSION",
  /** 学園・青春 */
  LOVE_YOUTH = "LOVE_YOUTH",
  /** ほのぼの */
  LOVE_HEARTWARM = "LOVE_HEARTWARM",
  /** キュンキュン */
  LOVE_HEARTBEATS = "LOVE_HEARTBEATS",
  /** BL */
  LOVE_BL = "LOVE_BL",
  /** 百合 */
  LOVE_YURI = "LOVE_YURI",
  /** 一般 */
  FANTASY_GENERAL = "FANTASY_GENERAL",
  /** ローファンタジー */
  FANTASY_LOW = "FANTASY_LOW",
  /** 現代ファンタジー */
  FANTASY_PRESENT = "FANTASY_PRESENT",
  /** 感動系 */
  FANTASY_IMPRESSION = "FANTASY_IMPRESSION",
  /** 理不尽・デスゲーム */
  FANTASY_UNFAIR = "FANTASY_UNFAIR",
  /** ダークファンタジー */
  FANTASY_DARK = "FANTASY_DARK",
  /** 謎解き */
  FANTASY_RIDDLE = "FANTASY_RIDDLE",
  /** 立ち向かう */
  FANTASY_CONFRONT = "FANTASY_CONFRONT",
  /** 魔法・超能力 */
  FANTASY_MAGIC = "FANTASY_MAGIC",
  /** 一般 */
  ISEKAI_GENERAL = "ISEKAI_GENERAL",
  /** 無双・最強 */
  ISEKAI_STRONGEST = "ISEKAI_STRONGEST",
  /** ハーレム */
  ISEKAI_HAREM = "ISEKAI_HAREM",
  /** 復讐・下克上 */
  ISEKAI_REVENGE = "ISEKAI_REVENGE",
  /** 理不尽 */
  ISEKAI_UNFAIR = "ISEKAI_UNFAIR",
  /** 日常系 */
  ISEKAI_USUAL = "ISEKAI_USUAL",
  /** 成長 */
  ISEKAI_GROWTH = "ISEKAI_GROWTH",
  /** 謎解き */
  ISEKAI_RIDDLE = "ISEKAI_RIDDLE",
  /** ギャグ・コメディー */
  ISEKAI_COMEDY = "ISEKAI_COMEDY",
  /** 一般 */
  DRAMA_GENERAL = "DRAMA_GENERAL",
  /** 学園 */
  DRAMA_SCHOOL = "DRAMA_SCHOOL",
  /** 職場 */
  DRAMA_WORK = "DRAMA_WORK",
  /** アイドル */
  DRAMA_IDOL = "DRAMA_IDOL",
  /** スポーツ・音楽 */
  DRAMA_SPORTMUSIC = "DRAMA_SPORTMUSIC",
  /** 夢を追う */
  DRAMA_DREAM = "DRAMA_DREAM",
  /** シリアス */
  DRAMA_SERIOUS = "DRAMA_SERIOUS",
  /** コミカル */
  DRAMA_COMICAL = "DRAMA_COMICAL",
  /** 一般 */
  ACTION_GENERAL = "ACTION_GENERAL",
  /** スパイ */
  ACTION_SPY = "ACTION_SPY",
  /** アウトロー */
  ACTION_OUTLAW = "ACTION_OUTLAW",
  /** ロボット */
  ACTION_ROBOT = "ACTION_ROBOT",
  /** ヒーロー */
  ACTION_HERO = "ACTION_HERO",
  /** 武術 */
  ACTION_MARTIALARTS = "ACTION_MARTIALARTS",
  /** 一般 */
  SF_GENERAL = "SF_GENERAL",
  /** 近未来技術（AI・VR） */
  SF_AIVR = "SF_AIVR",
  /** 異星人 */
  SF_ALIEN = "SF_ALIEN",
  /** 宇宙 */
  SF_SPACE = "SF_SPACE",
  /** パニック */
  SF_PANIC = "SF_PANIC",
  /** タイムリープ */
  SF_TIMELEAP = "SF_TIMELEAP",
  /** 人類滅亡 */
  SF_RUIN = "SF_RUIN",
  /** ロボット */
  SF_ROBOT = "SF_ROBOT",
  /** 空想科学 */
  SF_SCIENCE = "SF_SCIENCE",
  /** 一般 */
  MYSTERY_GENERAL = "MYSTERY_GENERAL",
  /** 現代 */
  MYSTERY_PRESENT = "MYSTERY_PRESENT",
  /** 1話完結 */
  MYSTERY_SHORT = "MYSTERY_SHORT",
  /** 学園 */
  MYSTERY_CAMPUS = "MYSTERY_CAMPUS",
  /** 脱出 */
  MYSTERY_ESCAPE = "MYSTERY_ESCAPE",
  /** クラシック */
  MYSTERY_CLASSIC = "MYSTERY_CLASSIC",
  /** 名コンビ */
  MYSTERY_COMBINATION = "MYSTERY_COMBINATION",
  /** 一般 */
  HORROW_GENERAL = "HORROW_GENERAL",
  /** 幽霊・化け物 */
  HORROW_GHOST = "HORROW_GHOST",
  /** サスペンスホラー */
  HORROW_SUSPENSE = "HORROW_SUSPENSE",
  /** パニック */
  HORROW_PANIC = "HORROW_PANIC",
  /** 感染 */
  HORROW_INFECTION = "HORROW_INFECTION",
  /** 人間の狂気 */
  HORROW_MADNESS = "HORROW_MADNESS",
  /** ゾンビ */
  HORROW_ZOMBIE = "HORROW_ZOMBIE",
  /** 怪談話 */
  HORROW_STORY = "HORROW_STORY",
  /** 一般 */
  HISTORY_GENERAL = "HISTORY_GENERAL",
  /** 剣豪小説 */
  HISTORY_SWORDMAN = "HISTORY_SWORDMAN",
  /** 市井小説 */
  HISTORY_SHISEI = "HISTORY_SHISEI",
  /** 伝奇小説 */
  HISTORY_CHUANQI = "HISTORY_CHUANQI",
  /** 捕物帳 */
  HISTORY_TORIMONO = "HISTORY_TORIMONO",
  /** 新撰組 */
  HISTORY_SHINSENGUMI = "HISTORY_SHINSENGUMI",
  /** 欧米 */
  HISTORY_WESTERN = "HISTORY_WESTERN",
  /** 中国 */
  HISTORY_CHINA = "HISTORY_CHINA",
  /** 平安 */
  HISTORY_HEIAN = "HISTORY_HEIAN",
  /** 一般 */
  COMEDY_GENERAL = "COMEDY_GENERAL",
  /** 恋愛 */
  COMEDY_LOVE = "COMEDY_LOVE",
  /** 同級生 */
  COMEDY_CLASSMATE = "COMEDY_CLASSMATE",
  /** 異世界 */
  COMEDY_ISEKAI = "COMEDY_ISEKAI",
  /** 非日常 */
  COMEDY_UNUSEAL = "COMEDY_UNUSEAL",
  /** 事件 */
  COMEDY_INCIDENT = "COMEDY_INCIDENT",
  /** 一般 */
  CHILDREN_GENERAL = "CHILDREN_GENERAL",
  /** 小学校高学年 */
  CHILDREN_HIGH = "CHILDREN_HIGH",
  /** 小学校低学年 */
  CHILDREN_LOW = "CHILDREN_LOW",
  /** 昔話 */
  CHILDREN_OLD = "CHILDREN_OLD",
  /** 幼児向け */
  CHILDREN_TODDLER = "CHILDREN_TODDLER",
  /**  */
  NONSUBGENRE = "NONSUBGENRE",
}
