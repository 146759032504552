
import Vue from "vue";
import { Auth } from "aws-amplify";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  name: "newPassword",
  props: {
    username: String,
  },
  data() {
    return {
      newPassword: "",
    };
  },
  methods: {
    async submit() {
      const { newPassword } = this;

      const user = await Auth.currentUserPoolUser();
      await Auth.completeNewPassword(user, newPassword, {});
    },
  },
});
