
import Vue from "vue";
import { Auth } from "aws-amplify";
import { isTwitterLogin, getAuth } from "../../lib/twitterAuth";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo() {
    if (process.env.NODE_ENV === "production") {
      return {};
    }
    return {
      meta: [
        {
          name: "robots",
          content: "none",
        },
      ],
    };
  },
  data() {
    return {
      userId: "",
      message: "",
      idPoolId: "",
    };
  },
  computed: {
    diagInfo() {
      const BR = "\n";
      const text = `▼ご利用のブラウザ情報${BR}${window.navigator.userAgent}${BR}▼ご利用のデバイス情報${BR}${window.navigator.platform}${BR}▼ユーザーID${BR}${this.userId}${BR}▼調査用情報${BR}${this.idPoolId}${BR}`;
      return text;
    },
    mail(): string {
      const BR = "%0d%0a";
      const mailto = "nola.contact.team@gmail.com";
      const subject = "Nolaに関する問い合わせ";

      const body = `下記にお問い合わせ内容をご記入ください。${BR}▼内容${BR}（お問い合わせ/ご意見・ご要望）${BR}▼詳しい内容${BR}こちらに内容をご記入ください。${BR}${BR}${BR}${BR}下記の項目は不具合の場合の調査に必要な情報です。${BR}${this.diagInfo.replace(
        new RegExp(BR, "g"),
        BR
      )}`;

      return `mailto:${mailto}?subject=${subject}&body=${body}`;
    },
    help() {
      return process.env.VUE_APP_NOTION_HELP!;
    },
  },
  async created() {
    const user = await getAuth();

    this.userId = !user ? "(not login)" : user + (isTwitterLogin() ? "(logined by twitter)" : "");

    const currentCredentials = (await Auth.currentCredentials()) as any;
    if (currentCredentials && currentCredentials.data) {
      this.idPoolId = currentCredentials.data.IdentityId;
    }
  },
  methods: {
    copyToClipboard() {
      const el: any = this.$refs.textarea;
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        const range = document.createRange();

        el.contentEditable = true;
        el.readOnly = false;
        range.selectNodeContents(el);

        const s = window.getSelection();
        s!.removeAllRanges();
        s!.addRange(range);

        el.setSelectionRange(0, 999999);

        el.contentEditable = false;
        el.readOnly = true;
      } else {
        el.select();
      }

      if (document.execCommand("copy")) {
        this.message = "クリップボードにコピーしました。";
      } else {
        this.message = "クリップボードにコピーできませんでした。";
      }
    },
  },
});
