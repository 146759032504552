
import CheckboxItem from "@/components/atoms/CheckboxItem.vue";
import Vue from "vue";

interface Props {
  item: {
    tags: string[];
  };
  settings: {
    id: string;
    name: string;
    operation: string;
    options: {
      id: string;
      name: string;
      defaultValue: boolean;
      disabled: boolean;
    }[];
  }[];
  error: string | boolean;
  nolaNovelItem: {
    tags: string[];
  };
  readonly: boolean;
}

interface Data {}

interface Computed {
  tag: string[];
}

interface Methods {
  onClick: (tag: string, checked: boolean) => void;
  alreadyChecked: (tag: string) => boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CheckboxItem,
  },
  model: {
    prop: "item",
    event: "select",
  },
  props: {
    item: {
      type: Object,
    },
    settings: {
      type: Array,
      required: true,
    },
    nolaNovelItem: {
      type: Object,
    },
    error: {},
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.$props.item?.tags) {
      return;
    }

    const tags = [] as string[];
    this.$props.settings.forEach((setting) => {
      setting.options.forEach((option) => {
        if (option.defaultValue || this.alreadyChecked(option.name)) {
          tags.push(option.name);
        }
      });
    });
    this.$emit("select", {
      tags,
    });
  },
  methods: {
    onClick(tag: string, checked: boolean) {
      if (this.$props.readonly) {
        return;
      }

      const tags = this.$props.item?.tags ?? { tags: [] as string[] };
      if (checked) {
        if (!tags.includes(tag)) {
          tags.push(tag);
        }
      } else {
        const index = tags.indexOf(tag);
        if (index !== -1) {
          tags.splice(index, 1);
        }
      }

      this.$emit("select", {
        tags,
      });
    },
    alreadyChecked(tag: string) {
      return this.$props.nolaNovelItem?.tag?.includes(tag);
    },
  },
});
