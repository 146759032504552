
import Vue from "vue";
import AgentList from "@/components/organisms/AgentList.vue";
import { User } from "@/lib/models";
import BeginnerIconOnboarding from "@/components/organisms/BeginnerIconOnboarding.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  data() {
    return {
      showBeginnerIconOnboarding: false,
    };
  },
  components: { AgentList, BeginnerIconOnboarding },
  computed: {
    user() {
      return this.$store.getters["userModule/user"];
    },
    hasUsedCommerceBanner() {
      return this.$store.getters["generalModule/hasUsedCommerceBanner"];
    },
    hasDisplayedBeginnerIconOnboardingPopup() {
      return this.$store.getters["generalModule/hasDisplayedBeginnerIconOnboardingPopup"];
    },
  },
  created() {
    if (!this.user.agentStartedAt) {
      this.$router.push({ name: "agentIntro" });
    }

    this.$store.dispatch("novelModule/initialize");

    // 商業道バナーを押下したことがあり、初心者アイコンオンボーディングポップアップを表示していない場合
    if (this.hasUsedCommerceBanner && !this.hasDisplayedBeginnerIconOnboardingPopup) {
      this.showBeginnerIconOnboarding = true;

      // 初心者アイコンオンボーディングポップアップ表示フラグを立てる
      this.$store.dispatch("generalModule/setDisplayedBeginnerIconOnboardingPopup", true);
    }
  },
});

interface Props {}

interface Data {
  showBeginnerIconOnboarding: boolean;
}

interface Computed {
  user: User;
  hasUsedCommerceBanner: boolean;
  hasDisplayedBeginnerIconOnboardingPopup: boolean;
}

interface Methods {}
