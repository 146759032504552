
import Vue from "vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo() {
    if (process.env.NODE_ENV === "production") {
      return {};
    }
    return {
      meta: [
        {
          name: "robots",
          content: "none",
        },
      ],
    };
  },
});
