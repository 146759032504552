
import Vue, { PropType } from "vue";
import ImageView from "@/components/atoms/ImageView.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ImageView },
  props: {
    id: String as PropType<string>,
    name: String as PropType<string>,
    subName: String as PropType<string>,
    image: String as PropType<string>,
  },
});

interface Props {
  id: string;
  name: string;
  subName: string;
  image?: string;
}

interface Data {}

interface Computed {}

interface Methods {}

export type SelectBoxItemProps = Props;
