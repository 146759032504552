var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cupertino-alert-dialog", {
    attrs: { title: "応募条件が一致していません", close: _vm.onClickOutSide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "px-1 py-1" }, [
              _vm._v(
                " 選択された作品「" +
                  _vm._s(_vm.$props.name) +
                  "」はコンテストの応募条件と一致してません。"
              ),
              _c("br"),
              _vm._v(
                " 応募を完了するには、必要な設定や投稿を行う必要があります。 "
              ),
            ]),
            _c("div", { staticClass: "submission-condition" }, [
              _c("div", { staticClass: "condition-content" }, [
                _c("div", { staticClass: "condition-label" }, [
                  _vm._v("投稿条件："),
                ]),
                _c("div", {
                  staticClass: "condition-value",
                  domProps: { innerHTML: _vm._s(_vm.submissionConditionText) },
                }),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c("div", { staticClass: "flex flex-row flex-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "w-full border-right-1 flex flex-center pointer py-1 left-hover left-bottom-radius-10 font-bold",
                  on: { click: _vm.onCancelClick },
                },
                [_c("span", [_vm._v("キャンセル")])]
              ),
              !_vm.published
                ? _c(
                    "div",
                    {
                      staticClass:
                        "w-full flex flex-center pointer py-1 right-hover right-bottom-radius-10 font-bold blue-text",
                      on: { click: _vm.onSubmitClick },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v("Nolaノベルで投稿する"),
                      ]),
                    ]
                  )
                : _vm.needsChangePublishStatus
                ? _c(
                    "div",
                    {
                      staticClass:
                        "w-full flex flex-center pointer py-1 right-hover right-bottom-radius-10 font-bold blue-text",
                      on: { click: _vm.onSubmitClick },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v("公開設定を変更する"),
                      ]),
                    ]
                  )
                : !_vm.hasEpisodes
                ? _c(
                    "div",
                    {
                      staticClass:
                        "w-full flex flex-center pointer py-1 right-hover right-bottom-radius-10 font-bold blue-text",
                      on: { click: _vm.onSubmitClick },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v("エピソードを投稿する"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm.isLoading
              ? _c("masked-loading", { staticClass: "loading" })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }