import { defineStore } from "pinia";
import { ref } from "vue";

export const useCommerceDebutGuideStore = defineStore("commerceDebutGuide", () => {
  // 選択されたメニュー番号を管理
  const selectedMenu = ref<string>("menu1");

  // メニュー番号を更新する関数
  function setSelectedMenu(menu: string) {
    selectedMenu.value = menu;
  }

  return {
    selectedMenu,
    setSelectedMenu,
  };
});
