
import Vue from "vue";
import AgentIntro from "@/components/organisms/AgentIntro.vue";
import { User } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { AgentIntro },
  computed: {
    user() {
      return this.$store.getters["userModule/user"];
    },
  },
  watch: {
    user: {
      handler(val) {
        if (!val.agentStartedAt) this.$router.push({ name: "agent" });
      },
      immediate: true,
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  user: User;
}

interface Methods {}
